import React, {FunctionComponent} from 'react';
import cameraIcon from "../../../../assets/icons/material/camera_color.svg";
import updateIcon from "../../../../assets/icons/material/update.svg";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import UserAvatar from "../../../data/UserAvatar";
import successColorIcon from "../../../../assets/icons/material/success_color.svg";
import Button from "../../../forms/Button";
import {useAuth} from "../../../../contexts/AuthContext";
import "./DSSettingsAbout.scss";

const DSSettingsAbout: FunctionComponent = () => {
    const {currentUser} = useAuth();
    const {selectedCamera, cameraSettings, cameraInfo, openUpdateModal} = useDashboardFunc();

    if (!selectedCamera || !cameraSettings || !cameraInfo || !currentUser) {
        return null;
    }

    const hasUpdate = selectedCamera.model.softwareVersion !== cameraSettings.softwareVersion;

    return (
        <div className={"camera-settings-about"}>
            <img src={cameraIcon} alt={'camera'} className={'camera-icon'}/>
            <div className={'main-data'}>
                <section>
                    <div className={'section-data'}>
                        <span>model</span>
                        <p>{selectedCamera.model.name}</p>
                    </div>
                </section>
                <section>
                    <div className={'section-data'}>
                        <span>wersja</span>
                        <p>{cameraSettings.softwareVersion}</p>
                    </div>
                </section>
                <section>
                    <div className={'section-data'}>
                        <span>sid</span>
                        <p>#{selectedCamera.sid}</p>
                    </div>
                </section>
                <section>
                    <UserAvatar user={selectedCamera.owner} side={"right"}/>
                    <div className={'section-data'}>
                        <span>właściciel</span>
                        <p>{selectedCamera.owner.nick} {(selectedCamera.owner.id === currentUser.id &&
                            <span className={'you'}>(Ty)</span>)}</p>
                    </div>
                </section>
            </div>
            <div className={'update-data ' + (hasUpdate ? "has-update" : "no-update")}>
                {hasUpdate ? (
                    <>
                        <span className={'update-text'}>Dostępna jest nowa wersja oprogramowania</span>
                        <Button onClick={() => {
                            openUpdateModal();
                        }} type={"filled-dark"}><img src={updateIcon} alt={'save'}/><span
                            className={'text'}>Aktualizuj</span> </Button>
                    </>
                ) : (
                    <>
                        <img src={successColorIcon} alt={'ok'}/>
                        <span className={'update-text'}>Oprogramowanie aktualne</span>
                    </>
                )}
            </div>


        </div>
    );
};

export default DSSettingsAbout;
