import React, {FunctionComponent} from 'react';
import "./DSLiveCameraTitle.scss";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import UserAvatar from "../../../data/UserAvatar";
import {useAuth} from "../../../../contexts/AuthContext";

const DSLiveCameraTitle: FunctionComponent = () => {
    const {currentUser} = useAuth();
    const {selectedCamera, cameraInfo} = useDashboardFunc();

    if (!currentUser || !selectedCamera || !cameraInfo) {
        return null;
    }

    return (
        <div className={"camera-live-title"}>
            <div className={"camera-live-title-owner"}>
                <UserAvatar user={cameraInfo.owner} type={"small"}/>
                <span className={'owner-name'}>{cameraInfo.owner.nick} {cameraInfo.owner.id === currentUser.id ? (
                    <span className={'me'}>(Ty)</span>) : null}</span>
            </div>
        </div>
    );
};

export default DSLiveCameraTitle;
