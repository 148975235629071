import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useDashboard} from "../../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../../contexts/DashboardFuncContext";
import InfoBlock from "../InfoBlock";
import lightIcon from "../../../../../assets/icons/material/light.svg";
import takePictureIcon from "../../../../../assets/icons/material/take_picture.svg";
import dayjs from 'dayjs';
import {CameraInfoOnline} from "../../../../../data/camera";
import {Messages} from "../../../../../data/messages";
import {useAlert} from "../../../../../contexts/AlertContext";
import Spinner from "../../../../util/Spinner";
import {picNameToDate} from "../../../../../util/utils";

const DSLiveInfoOnlineActions: FunctionComponent = () => {
    const {once, send} = useDashboard();
    const {selectedCamera, cameraInfo, refreshCameraInfo, cameraHistory} = useDashboardFunc();
    const {addAlert} = useAlert();
    const [flash, setFlash] = useState(false);
    const [flashLoading, setFlashLoading] = useState(false);
    const flashRef = useRef(false);

    useEffect(() => {
        if (!(cameraInfo instanceof CameraInfoOnline)) {
            setFlash(false);
            flashRef.current = false;
            return;
        }

        setFlash(cameraInfo.flash);
        flashRef.current = cameraInfo.flash;
    }, [cameraInfo])

    if (!cameraInfo || !selectedCamera || !(cameraInfo instanceof CameraInfoOnline)) {
        return null;
    }

    const takePic = () => {
        send(Messages.REQUEST_CAMERA_TAKE_PICTURE, JSON.stringify({id: selectedCamera.id}));
    }

    const changeLed = () => {
        setFlashLoading(true);
        const promise = once(Messages.STATUS, 5000, (data) => {
            const json = JSON.parse(data);
            if (!json) {
                return false;
            }

            if (json['name'] !== "request_led") {
                return false;
            }

            return json['camera'] === selectedCamera.id;
        })

        const newState = !flashRef.current;

        send(Messages.REQUEST_LED, {
            camera_id: selectedCamera.id,
            state: !flashRef.current
        });

        promise.then((data) => {
            if (!data) {
                addAlert(lightIcon, (newState ? "Wł" : "Wył") + "ączanie diody nie powiodło się.", null, 'error');
                setFlashLoading(false);
                return;
            }

            const json = JSON.parse(data);
            if (!json) {
                addAlert(lightIcon, (newState ? "Wł" : "Wył") + "ączanie diody nie powiodło się.", null, 'error');
                setFlashLoading(false);
                return;
            }

            setFlash(newState);
            flashRef.current = newState;

            if (json['status']) {
                addAlert(lightIcon, newState ? "Dioda zaczęła świecić" : "Dioda przestała świecić", null, 'success');
            } else {
                addAlert(lightIcon, (newState ? "Wł" : "Wył") + "ączanie diody nie powiodło się. Kod błędu: " + json['side'] + json['error'], null, 'error');
            }

            setFlashLoading(false);
        });
    }

    const lastPicture: Date | null = cameraInfo.lastPictures.length === 0 ? null : picNameToDate(cameraInfo.lastPictures[0])

    return (
        <>
            <InfoBlock title={"Dioda"} icon={lightIcon} onClick={() => changeLed()}
                       classes={'diode ' + (flash ? "selected" : "")}>
                {flashLoading ? <Spinner type={flash ? "background" : "primary"}/> : (flash ? "Włączona" : "Wyłączona")}
            </InfoBlock>
            <InfoBlock title={"Zrób zdjęcie"} icon={takePictureIcon} onClick={() => takePic()}>
                {lastPicture ? dayjs(lastPicture).fromNow() : "Brak zdjęć"}
            </InfoBlock>
        </>
    );
};

export default DSLiveInfoOnlineActions;
