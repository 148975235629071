import React, {FunctionComponent} from 'react';
import "./Dashboard.scss";
import NavbarDashboard from "../../components/dashboard/NavbarDashboard";
import DashboardRouter from "../../components/dashboard/content/DashboardRouter";
import useProtected from "../../hooks/useProtected";
import CameraMenuContext from "../../contexts/CameraMenuContext";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Dashboard: FunctionComponent = () => {
    useProtected();
    useDocumentTitle("MCam | Panel");

    return (
        <div className={'dashboard'}>
            <NavbarDashboard/>
            <CameraMenuContext>
                <DashboardRouter/>
            </CameraMenuContext>
        </div>
    );
};

export default Dashboard;
