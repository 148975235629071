import React, {FunctionComponent} from 'react';
import {useDashboard} from "../../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../../contexts/DashboardFuncContext";
import InfoBlock from "../InfoBlock";
import connectionIcon from "../../../../../assets/icons/material/connection.svg";
import dayjs from 'dayjs';
import {CameraInfoOffline} from "../../../../../data/camera";

const DSLiveInfoOffline: FunctionComponent = () => {
    const {subscribe, unsubscribe, send, isConnected} = useDashboard();
    const {selectedCamera, cameraInfo, refreshCameraInfo} = useDashboardFunc();

    if (!cameraInfo || !selectedCamera || !(cameraInfo instanceof CameraInfoOffline)) {
        return null;
    }

    const lastConnected = cameraInfo.lastLog ? dayjs(cameraInfo.lastLog.date).fromNow() : "Nigdy";

    return (
        <>
            <InfoBlock title={"Ostatnie połączenie"} icon={connectionIcon}>
                {lastConnected}
            </InfoBlock>
        </>
    );
};

export default DSLiveInfoOffline;
