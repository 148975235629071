import React, {FunctionComponent} from 'react';
import "./ValueChooser.scss";


interface ValueProps {
    value: number;
    values?: [{ value: number, name: string }];
    onSet: (newValue: number) => void;
    classes?: string;
}

type Props = ValueProps;

const ValueChooser: FunctionComponent<Props> = ({
                                                    value,
                                                    values = [{value: -2, name: "Bardzo niska"}, {
                                                        value: -1,
                                                        name: "Niska"
                                                    }, {value: 0, name: "Średnia"}, {
                                                        value: 1,
                                                        name: "Wysoka"
                                                    }, {value: 2, name: "Bardzo wysoka"}],
                                                    onSet,
                                                    classes = ""
                                                }) => {
    return (
        <div className={'value-chooser ' + classes}>
            {values.map((val, index) => (
                <div className={'quality index-' + index + " " + (val.value === value ? "selected" : "")}
                     key={'f-' + index} onClick={() => {
                    onSet(val.value)
                }}>
                    <div className={'line'}/>
                    <h5>{val.name}</h5>
                </div>
            ))}
        </div>
    );
};

export default ValueChooser;
