import React, {FunctionComponent} from 'react';
import './WiFiCredentials.scss';

interface WiFiCredProps {
    type: "sid" | "pass";
    onSet: (value: string | null) => boolean;
    value: string;
}

type Props = WiFiCredProps;

const WiFiCredentials: FunctionComponent<Props> = ({type, onSet, value}) => {

    return (
        <div className={'wifi-credentials'}>
            <input type={type === 'sid' ? "text" : "password"} key={'wifi_' + type} onChange={(e) => {
                const val = e.target.value;
                if (val == null || val.replace(" ", "").length === 0) {
                    onSet(null);
                } else {
                    onSet(val);
                }
            }} defaultValue={value} autoComplete={'new-password'} autoCapitalize={'off'} autoCorrect={'off'}/>
        </div>
    );
};

export default WiFiCredentials;
