import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import menuIcon from "../../../../../assets/icons/material/menu_vertical.svg";
import "./TimezoneChooser.scss";
import Tooltip from "../../../../data/Tooltip";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

interface TimezoneProps {
    timezone: number;
    onSet: (timezone: number) => void;
}

type Props = TimezoneProps;

interface Timezone {
    offset: number;
}

const timezones: Timezone[] = [];

for (let i = -12; i < 12; i++) {
    timezones.push({offset: i});
}

const addZero = (num: number): string => {
    return num > 9 ? (num + "") : ('0' + num);
}

const TimezoneChooser: FunctionComponent<Props> = ({timezone, onSet}) => {
    const currentOffset = timezone / 3600;

    const [timezoneTooltipActive, setTimezoneTooltipActive] = useState(false);
    const timezoneRef = useRef<HTMLDivElement>(null);
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <div className={'timezone-chooser'}>
            <div className={'timezone-chooser-box'} ref={timezoneRef} onClick={() => {
                setTimezoneTooltipActive(!timezoneTooltipActive)
            }}>
                <span>
                    UTC{currentOffset >= 0 ? '+' : ''}{currentOffset}
                </span>
                <img src={menuIcon} alt={'...'} className={'menu-icon'}/>
            </div>

            <Tooltip element={timezoneRef} position={"right"} show={timezoneTooltipActive} classes={"timezone-tooltip"}
                     centered={false} arrow={false}>
                <h3>Ustaw czas</h3>
                <div className={'current-time'}>
                    <p className={'camera-time-info'}>Czas kamery</p>
                    <span>{(() => {
                        const date = new Date(time.getTime() + currentOffset * 3600 * 1000);

                        return addZero(date.getHours()) + ":" + addZero(date.getMinutes());
                    })()}</span>
                </div>
                <div className={'times'}>
                    <OverlayScrollbarsComponent options={{
                        overflowBehavior: {
                            x: "hidden",
                            y: "scroll"
                        }
                    }}>
                        {timezones.map((timezone) => (
                            <div key={timezone.offset}
                                 className={'timezone ' + (timezone.offset === currentOffset ? "selected" : "")}
                                 onClick={() => {
                                     onSet(timezone.offset * 3600);
                                     setTimezoneTooltipActive(false);
                                 }}>
                            <span className={'timezone-name'}>
                                UTC{timezone.offset >= 0 ? '+' : ''}{timezone.offset}
                            </span>
                                <span className={'timezone-time'}>
                                {(() => {
                                    const date = new Date(time.getTime() + (timezone.offset * 3600 * 1000));

                                    return addZero(date.getHours()) + ":" + addZero(date.getMinutes());
                                })()}
                            </span>
                            </div>
                        ))}
                    </OverlayScrollbarsComponent>
                </div>
            </Tooltip>
        </div>
    );
};

export default TimezoneChooser;
