import React, {FunctionComponent, useEffect, useState} from 'react';
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import {useDashboard} from "../../../../contexts/DashboardContext";
import {Messages} from "../../../../data/messages";
import "./DSHistoryPicture.scss";
import {useSearchParams} from "react-router-dom";
import {picNameToDate} from "../../../../util/utils";
import dayjs from "dayjs";
import {getImageFromCache, saveImageToCache} from "../../../../util/cache";

interface HistoryPictureProps {
    cameraId: number;
    index: number,
    picName: string;
    type: "small" | "big";
    imageDataPre: string | null;
    onClick: (image: string) => void;
}

type Props = HistoryPictureProps;

const DSHistoryPicture: FunctionComponent<Props> = ({cameraId, index, picName, type, imageDataPre, onClick}) => {
    const [imageData, setImageData] = useState<string | null>(imageDataPre);
    const {subscribe, unsubscribe, send} = useDashboard();
    const {selectedCamera, cameraHistory} = useDashboardFunc();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!selectedCamera) {
            return;
        }

        let isMyPicNext = false;

        // first pic, request picture
        if (index == 0) {
            const cache = getImageFromCache(cameraId, picName);
            if (!cache) {
                setTimeout(() => {
                    send(Messages.REQUEST_PICTURE, JSON.stringify({id: selectedCamera.id, name: picName}))
                }, 0)
            } else {
                if (cameraHistory) {
                    cameraHistory.setImage(picName, cache);
                }
                setImageData(cache);
            }
        }

        const sub1 = subscribe(Messages.RESPOND_PICTURE_PRE, (data) => {
            const json = JSON.parse(data);
            console.log("GOT PRE: ");
            console.log(json);
            if (json) {
                if (selectedCamera && selectedCamera.id === json['camera']) {
                    const name = json['name'];
                    if (name === picName) {
                        console.log("my next");
                        isMyPicNext = true;
                    }
                } else {
                    isMyPicNext = false;
                }
            } else {
                isMyPicNext = false;
            }
        });
        const sub2 = subscribe(Messages.RESPOND_PICTURE, (data) => {
            if (isMyPicNext) {
                isMyPicNext = false;
                let bytes = new Uint8Array(data);
                let binary = '';
                let len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i])
                }

                const image = "data:image/jpg;base64," + window.btoa(binary);
                setImageData(image);

                console.log("received photo");

                if (cameraHistory) {
                    cameraHistory.setImage(picName, image);
                    saveImageToCache(cameraId, picName, image);
                }
            }
        });

        return () => {
            unsubscribe(sub1);
            unsubscribe(sub2);
        }
    }, []);

    if (!selectedCamera) {
        return null;
    }

    const date = picNameToDate(picName);

    return (
        <div
            className={'camera-history-picture ' + (imageData ? "" : "loading") + ' ' + (searchParams.get("sel") && searchParams.get('sel') === picName ? "selected" : "")}
            onClick={() => {
                if (imageData) {
                    onClick(imageData);
                }
            }}>
            <div className={'camera-history-picture-image'}>
                {imageData && <img src={imageData} alt={picName}/>}
            </div>
            <div className={'camera-history-picture-name'}>
                <span className={'date'}>
                    {dayjs(date).format("YYYY-MM-DD")}
                </span>
                <span className={'time'}>
                    {dayjs(date).format("HH:mm:ss")}
                </span>
            </div>
        </div>
    );
};

export default DSHistoryPicture;
