import React, {FunctionComponent, useRef, useState} from 'react';
import "./Avatar.scss";
import noneAvatar from './../../assets/icons/material/avatar.svg';
import Tooltip from "./Tooltip";
import {ResidualUser} from "../../data/user";


const UserAvatar: FunctionComponent<{ user: ResidualUser, side?: "left" | "right", type?: "normal" | "small" }> = ({
                                                                                                                       user,
                                                                                                                       side = "right",
                                                                                                                       type = "normal"
                                                                                                                   }) => {
    const [hasFocus, setHasFocus] = useState<boolean>(false);

    const avatarRef = useRef<HTMLDivElement>(null);

    return (
        <div className={'user-avatar-wrapper'} ref={avatarRef} tabIndex={0} onFocus={() => setHasFocus(true)}
             onBlur={() => setHasFocus(false)}>
            <div className={'user-avatar ' + type}>
                <div className={'outline'}
                     style={{borderColor: '#' + user.color, boxShadow: '0 4px 8px #' + user.color + "78"}}>
                    <img src={noneAvatar} alt={'You'}/>
                </div>
            </div>
            <Tooltip element={avatarRef} position={side === "left" ? "right" : "left"}
                     show={hasFocus} centered={true} classes={'user-tooltip'} arrow={false}>
                <div className={'user-tooltip-avatar'}>
                    <div className={'user-tooltip-avatar-bg'} style={{backgroundColor: '#' + user.color}}/>
                    <div className={'user-tooltip-avatar-image'}>
                        <img src={noneAvatar} alt={'You'}/>
                    </div>
                </div>
                <h3 className={'user-tooltip-nick'}>
                    {user.nick}
                </h3>
            </Tooltip>
        </div>
    );
};

export default UserAvatar;
