import React, {FunctionComponent} from 'react';
import "./Footer.scss";
import mcamDark from './../../assets/icons/mcam_dark.svg';

const Footer: FunctionComponent = () => {
    return (
        <footer>
            <div className={"footer-holder"}>
                <img src={mcamDark} alt={"MCam"}/>
                <span className={'copyright'}>Copyright matez.net 2022</span>
                <span className={'rights'}>All rights reserved</span>
            </div>
        </footer>
    );
};

export default Footer;
