import React, {FunctionComponent, useEffect} from 'react';
import "./DSSettingsView.scss";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import {useDashboard} from "../../../../contexts/DashboardContext";
import {Messages} from "../../../../data/messages";
import Spinner from "../../../util/Spinner";
import DSSettingsAbout from "./DSSettingsAbout";
import DSSettingsUsers from "./DSSettingsUsers";
import DSSettingsCamera from './DSSettingsCamera';
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

const DSSettingsView: FunctionComponent = () => {
    const {selectedCamera, cameraInfo, cameraSettings} = useDashboardFunc();
    const {subscribe, unsubscribe, send} = useDashboard();

    useEffect(() => {
        if (!selectedCamera) {
            return;
        }

        if (!cameraSettings) {
            send(Messages.REQUEST_SETTINGS_DATA, JSON.stringify({id: selectedCamera.id}))
        }
        if (!cameraInfo) {
            send(Messages.REQUEST_CAMERA_INFO, JSON.stringify({id: selectedCamera.id}))
        }
    }, []);

    const admin = cameraSettings?.admin || false;

    if (!selectedCamera) {
        return null;
    }
    return (
        <div className={'camera-settings-view ' + (!cameraSettings || !cameraInfo ? "loading" : "")}>
            {!cameraSettings || !cameraInfo ? (<Spinner type={"primary"}/>) : (
                <>
                    <DSSettingsAbout/>
                    <OverlayScrollbarsComponent options={{
                        overflowBehavior: {
                            x: "hidden",
                            y: "scroll"
                        }
                    }}>
                        <DSSettingsUsers/>
                        {admin ? (<DSSettingsCamera/>) : null}
                    </OverlayScrollbarsComponent>
                </>
            )}
        </div>
    );
};

export default DSSettingsView;
