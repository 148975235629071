import React, {FunctionComponent, useState} from 'react';
import addIcon from './../../../../assets/icons/material/add.svg';
import closeIcon from './../../../../assets/icons/material/close.svg';
import FormProvider, {ValueHandlerProps} from "../../../../contexts/FormContext";
import InputField, {InputTypes} from "../../../forms/InputField";
import nickIcon from './../../../../assets/icons/material/user.svg';
import hashIcon from './../../../../assets/icons/material/hash.svg';
import Button from "../../../forms/Button";
import Spinner from "../../../util/Spinner";
import "./DSSettingsAddAccessorModal.scss";
import {DashboardContextProps, useDashboard} from "../../../../contexts/DashboardContext";
import {Messages} from "../../../../data/messages";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";

interface AccessorAddModalProps {
    onClose: (added: boolean) => void;
}

type Props = AccessorAddModalProps;

const sendAction = async (context: DashboardContextProps, cameraId: number, nick: string, hash: string, admin: boolean = false): Promise<{ status: number, error?: number }> => {
    const promise = context.once(Messages.RESPOND_ACCESSOR_RESULT_ADD, 5000);
    context.send(Messages.REQUEST_ACCESSOR_ADD, {
        camera_id: cameraId,
        nick: nick,
        hash: hash,
        admin: admin
    });

    const data = await promise;
    if (!data) {
        return {status: 404};
    }

    const json = JSON.parse(data);
    if (!json) {
        return {status: 400}
    }

    if (json['success']) {
        return {status: 200};
    } else {
        if (json.hasOwnProperty('code')) {
            return {status: json['status'], error: json['code']};
        } else {
            return {status: json['status']};
        }
    }
}

const DSSettingsAddAccessorModal: FunctionComponent<Props> = ({onClose}) => {
    const dashboardContext = useDashboard();
    const {selectedCamera} = useDashboardFunc();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [addAccSubmit, setAddAccSubmit] = useState<() => boolean>(() => {
        return () => false
    });
    const [addAccGetter, setAddAccGetter] = useState<(() => ValueHandlerProps | null)>(() => {
        return () => null
    });
    const [addAccInvalidSetter, setAddAccInvalidSetter] = useState<(((name: string, invalidValue: string, error: string) => void) | null)>(() => {
        return null
    });

    if (!selectedCamera) {
        return null;
    }

    const submit = () => {
        if (!addAccSubmit || !addAccSubmit()) {
            return
        }

        const getter = addAccGetter();
        if (!getter) {
            return;
        }

        const nick = getter['nick'].value;
        const hash = getter['hash'].value;

        if (!nick) {
            return;
        }

        setLoading(true);
        (async () => {
            setErrorMessage(null);
            const response = await sendAction(dashboardContext, selectedCamera.id, nick, hash);
            console.log(response);
            if (response.status === 200) {
                onClose(true);
            } else {
                switch (response.status) {
                    case 400:
                        if (response.error != null && response.error !== 0 && addAccInvalidSetter) {
                            if (response.error === -1) {
                                addAccInvalidSetter("nick", nick, "Ten użytkownik nie istnieje.")
                            } else if (response.error === -2) {
                                addAccInvalidSetter("nick", nick, "Ten użytkownik jest już dodany.")
                            } else if (response.error === -3) {
                                setErrorMessage("Przekroczyłeś limit użytkowników kamery.")
                            } else if (response.error === -4) {
                                addAccInvalidSetter("nick", nick, "Właściciel kamery nie może być dodany.")
                            } else if (response.error === -5) {
                                addAccInvalidSetter("hash", hash, "Nieprawidłowy hash.")
                            }

                            break
                        }

                        setErrorMessage("Nieprawidłowe żadanie. Spróbuj jeszcze raz.")
                        break

                    case 401:
                        setErrorMessage("Błąd autoryzacji. Spróbuj jeszcze raz.")
                        break
                    case 403:
                        setErrorMessage("Odmowa dostępu.")
                        break
                    case 500:
                        setErrorMessage("Błąd w przetwarzaniu danych. Spróbuj jeszcze raz.")
                        break
                    default:
                        setErrorMessage("Błąd " + response.status + ". Spróbuj jeszcze raz.")
                        break
                }
            }
            setLoading(false);
        })();
    }

    return (<>
        <div className={'modal-add-accessor-title'}>
            <img src={addIcon} alt={'add'} className={'modal-title-icon'}/>
            <h2 className={'modal-title'}>Dodaj użytkownika kamery<span className={'accent-dot'}>.</span></h2>
            <img src={closeIcon} alt={'add'} className={'modal-close-icon'} onClick={() => onClose(false)}/>
        </div>
        <div className={'modal-add-accessor-form'}>
            <FormProvider submitHandler={(h) => setAddAccSubmit(() => h)} valueHandler={(h) => setAddAccGetter(() => h)}
                          invalidSetHandler={(h) => setAddAccInvalidSetter(() => h)}>
                <InputField key={'acc_nick'} name={'nick'} title={"Nazwa użytkownika"} icon={nickIcon}
                            type={InputTypes.nick}
                ></InputField>
                <InputField key={'acc_hash'} name={'hash'} title={"Hash użytkownika"} icon={hashIcon}
                            type={InputTypes.hash}
                ></InputField>
                <div className={"buttons"}>
                    <Button type={"filled"} onClick={() => {
                        submit()
                    }}>
                        {loading ? (
                            <Spinner type={'background'}></Spinner>
                        ) : (<span className={'text'}>Dodaj użytkownika</span>)}
                    </Button>
                </div>
            </FormProvider>
            <div className={"error-message"}>
                {errorMessage && (<span>{errorMessage}</span>)}
            </div>
        </div>
    </>);
};

export default DSSettingsAddAccessorModal;
