import React, {createContext, FunctionComponent, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import "./AlertContext.scss";
import closeIcon from "../assets/icons/material/close.svg";

interface AlertProviderProps {
    children: ReactNode;
}

type Props = AlertProviderProps;

export interface AlertContextProps {
    addAlert: (icon: string, text: React.ReactNode, buttons?: React.ReactNode, classes?: string, onClose?: () => void, time?: number) => AlertEntry;
    closeAlert: (entry: AlertEntry) => void;
}

const AlertContext = createContext<AlertContextProps | null>(null);

export function useAlert() {
    return useContext(AlertContext) || {} as AlertContextProps;
}

class AlertEntry {
    public readonly id: string;
    public readonly icon: string;
    public readonly text: ReactNode;
    public readonly buttons?: ReactNode;
    public readonly classes: string;
    public readonly time: number;
    public timeLeft: number = 0;
    public addDate: Date | null = null;
    public onClose: (() => void) | null = null;
    public exists: boolean = false;

    constructor(id: string, icon: string, text: React.ReactNode, buttons?: React.ReactNode, classes: string = "", onClose?: () => void, time: number = 3000) {
        this.id = id;
        this.icon = icon;
        this.text = text;
        this.buttons = buttons;
        this.classes = classes;
        if (onClose) {
            this.onClose = onClose;
        }
        this.time = time;
    }
}

const AlertProvider: FunctionComponent<Props> = ({children}) => {
    const [entries, setEntries] = useState<AlertEntry[]>([]);
    const entriesRef = useRef<AlertEntry[]>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            let entriesCapture = entriesRef.current.filter((entry) => {
                entry.timeLeft -= 500;
                let exists = entry.timeLeft > 0;

                if (!exists) {
                    if (entry.onClose) {
                        entry.onClose();
                    }
                }

                entry.exists = exists;

                return exists;
            });

            entriesRef.current = entriesCapture;
            setEntries(entriesCapture);
        }, 500);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const addAlert = (icon: string, text: React.ReactNode, buttons?: React.ReactNode, classes?: string, onClose?: () => void, time?: number): AlertEntry => {
        const alert = new AlertEntry(Math.random().toString(36).replace('0.', ''), icon, text, buttons, classes, onClose, time);
        alert.timeLeft = alert.time;

        entriesRef.current.push(alert);
        setEntries(entriesRef.current);

        alert.exists = true;

        return alert;
    }

    const closeAlert = (entry: AlertEntry) => {
        if (entry.exists) {
            entry.timeLeft = 500;
            setEntries(entries);
        }
    }

    const contextProps: AlertContextProps = {
        addAlert,
        closeAlert
    }

    return (
        <AlertContext.Provider value={contextProps}>
            {children}
            <div className={'alerts'}>
                {entries.sort((a, b) => {
                    if (!a.addDate || !b.addDate) {
                        return 0;
                    }

                    return b.addDate.getTime() - a.addDate.getTime();
                }).map((entry, index) => (
                    <div
                        className={'alert ' + entry.classes + " " + (entry.timeLeft <= 500 ? "alert-hide" : "alert-show")}
                        key={entry.id}>
                        <div className={'alert-content'}>
                            <img src={entry.icon} alt={'alert-icon'} className={'alert-icon'}/>
                            <div className={'alert-text'}>
                                {entry.text}
                            </div>
                            {entry.buttons ? (
                                <div className={'alert-buttons'}>
                                    {entry.buttons}
                                </div>
                            ) : null}
                            <img src={closeIcon} alt={'close'} className={'alert-close'} onClick={() => {
                                closeAlert(entry)
                            }}/>
                            <div className={'alert-background'}/>
                        </div>
                    </div>
                ))}
            </div>
        </AlertContext.Provider>
    );
};

export default AlertProvider;
