export function getImageFromCache(camId: number, picName: string): string | null {
    const imageCache = window.localStorage.getItem("pictures");
    if (!imageCache) {
        return null;
    }

    const json: { [key: number]: { [key: string]: { image: string, expires: Date } } } = JSON.parse(imageCache);
    if (!json) {
        return null;
    }

    const cameraCache: { [key: string]: { image: string, expires: Date } } = json[camId];
    if (!cameraCache) {
        return null;
    }

    return cameraCache[picName]?.image;
}

export function saveImageToCache(camId: number, picName: string, image: string) {
    const imageCache: string | null = window.localStorage.getItem("pictures");
    let expires = new Date(new Date().getTime() + 1000 * 60 * 24 * 30);
    if (!imageCache) {
        let data: { [key: number]: { [key: string]: { image: string, expires: Date } } } = {};
        let cameraCache: { [key: string]: { image: string, expires: Date } } = {};
        cameraCache[picName] = {image, expires};
        data[camId] = cameraCache;
        window.localStorage.setItem("pictures", JSON.stringify(data))
    } else {
        const json: { [key: number]: { [key: string]: { image: string, expires: Date } } } = JSON.parse(imageCache);
        if (!json) {
            let data: { [key: number]: { [key: string]: { image: string, expires: Date } } } = {};
            let cameraCache: { [key: string]: { image: string, expires: Date } } = {};
            cameraCache[picName] = {image, expires};
            data[camId] = cameraCache;
            window.localStorage.setItem("pictures", JSON.stringify(data))
        } else {
            let cameraCache: { [key: string]: { image: string, expires: Date } } = json[camId];
            if (!cameraCache) {
                cameraCache = {};
                cameraCache[picName] = {image, expires};
                json[camId] = cameraCache;
                window.localStorage.setItem("pictures", JSON.stringify(json))
            } else {
                cameraCache[picName] = {image, expires};
                json[camId] = cameraCache;
                window.localStorage.setItem("pictures", JSON.stringify(json))
            }
        }
    }
}

export function refreshCache() {
    try {
        const imageCache = window.localStorage.getItem("pictures");
        if (!imageCache) {
            return null;
        }

        let json: { [key: number]: { [key: string]: { image: string, expires: string } } } = JSON.parse(imageCache);
        if (!json) {
            return null;
        }

        let currDate = new Date().getTime();

        for (let cameraId in json) {
            const cameraCache = json[cameraId];
            for (let picName in cameraCache) {
                if (new Date(cameraCache[picName].expires).getTime() < currDate) {
                    delete cameraCache[picName];
                }
            }
        }

        window.localStorage.setItem("pictures", JSON.stringify(json))
    } catch (e) {
        console.log("Error reading storage: " + e);
        window.localStorage.removeItem("pictures");
    }
}