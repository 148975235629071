import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from 'react-cookie';
import OverlayScrollbars from "overlayscrollbars";
import * as dayjs from 'dayjs'
import 'dayjs/locale/pl'
import dayJsRelativeTime from 'dayjs/plugin/relativeTime';
import dayJsWeekday from 'dayjs/plugin/weekday';
import dayJsCalendar from 'dayjs/plugin/calendar';
import {refreshCache} from "./util/cache";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

dayjs.locale('pl');
dayjs.extend(dayJsRelativeTime);
dayjs.extend(dayJsWeekday);
dayjs.extend(dayJsCalendar);

OverlayScrollbars(document.body, {
    nativeScrollbarsOverlaid: {
        initialize: false
    },
    overflowBehavior: {
        x: "hidden"
    }
});

refreshCache();

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CookiesProvider>
                <App/>
            </CookiesProvider>
        </BrowserRouter>
    </React.StrictMode>
);

/*
<OverlayScrollbarsComponent
                    options={{
                        className: "os-theme-light",
                        overflowBehavior: {
                            x: "hidden"
                        }
                    }}
                >

                </OverlayScrollbarsComponent>
 */