import React, {FunctionComponent, useEffect, useState} from 'react';
import {Camera} from "../../../../data/camera";
import "./DSCameraPreview.scss";
import {getAverageColor} from "../../../../util/utils";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";

interface DashboardCameraPreviewProps {
    camera: Camera;
    onSelect: () => void;
}

type Props = DashboardCameraPreviewProps;

const DSCameraPreview: FunctionComponent<Props> = ({camera, onSelect}) => {
    const [color, setColor] = useState<string | null>(null);
    const [cameraPreview, setCameraPreview] = useState<string>(camera.getLocalScreenshot());
    const {selectedCamera} = useDashboardFunc();

    useEffect(() => {
        getAverageColor(cameraPreview, (c) => {
            if (c) {
                setColor('#' + c);
            }
        });

    }, []);

    return (
        <div
            className={'camera-preview ' + (selectedCamera?.id === camera.id ? "selected" : "") + ' ' + (camera.online ? "" : "offline")}
            onClick={onSelect}>
            <div className={'image-preview'}
                 style={color ? {outlineColor: color, boxShadow: '0 4px 8px ' + color + "78"} : {}}>
                <div className={'status status-' + (camera.online ? "online" : "offline")}>
                    {camera.online ? "online" : "offline"}
                </div>
                <img src={cameraPreview} alt={'Preview'}/>
            </div>
            <h3>{camera.name}</h3>
        </div>
    );
};

export default DSCameraPreview;
