import React, {FunctionComponent, useEffect, useState} from 'react';
import "./FormatChooser.scss";

export class Format {
    public width: number;
    public height: number;
    public name: string;


    constructor(width: number, height: number, name: string) {
        this.width = width;
        this.height = height;
        this.name = name;
    }
}

export const Formats: Format[] = [
    new Format(240, 176, "HQVGA"),
    new Format(340, 240, "QVGA"),
    new Format(480, 320, "HVGA"),
    new Format(640, 480, "VGA"),
    new Format(800, 600, "SVGA"),
    new Format(1280, 720, "HD"),
]

interface FormatProps {
    format: string;
    onSet: (format: Format) => boolean;
}

type Props = FormatProps;

const FormatChooser: FunctionComponent<Props> = ({format, onSet}) => {
    const [selectedFormat, setSelectedFormat] = useState<Format | null>(null);

    useEffect(() => {
        const formatObj = Formats.find((f) => f.name === format);
        if (formatObj) {
            setSelectedFormat(formatObj);
        } else {
            setSelectedFormat(null);
        }
    }, [format])


    return (
        <div className={'format-chooser'}>
            {Formats.map((format, index) => (
                <div className={'format index-' + index + " " + (format === selectedFormat ? "selected" : "")}
                     key={'f-' + index} onClick={() => {
                    if (onSet(format)) setSelectedFormat(format);
                }}>
                    <div className={'line'}/>
                    <h5>{format.name}</h5>
                    <span>{format.width}x{format.height}</span>
                </div>
            ))}
        </div>
    );
};

export default FormatChooser;
