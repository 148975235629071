import React, {FunctionComponent, useEffect, useState} from 'react';
import "./QualityChooser.scss";

export class Quality {
    public value: number;
    public name: string;


    constructor(value: number, name: string) {
        this.value = value;
        this.name = name;
    }
}

export const Qualities: Quality[] = [
    new Quality(0, "Bardzo niska"),
    new Quality(1, "Niska"),
    new Quality(2, "Średnia"),
    new Quality(3, "Wysoka"),
    new Quality(4, "Bardzo wysoka")
]

interface QualityProps {
    quality: number;
    onSet: (format: Quality) => boolean;
}

type Props = QualityProps;

const FormatChooser: FunctionComponent<Props> = ({quality, onSet}) => {
    const [selectedQuality, setSelectedQuality] = useState<Quality | null>(null);

    useEffect(() => {
        const qualityObj = Qualities.find((q) => q.value === quality);
        if (qualityObj) {
            setSelectedQuality(qualityObj);
        } else {
            setSelectedQuality(null);
        }
    }, [quality])

    return (
        <div className={'quality-chooser'}>
            {Qualities.map((quality, index) => (
                <div className={'quality index-' + index + " " + (quality === selectedQuality ? "selected" : "")}
                     key={'f-' + index} onClick={() => {
                    if (onSet(quality)) setSelectedQuality(quality);
                }}>
                    <div className={'line'}/>
                    <h5>{quality.name}</h5>
                </div>
            ))}
        </div>
    );
};

export default FormatChooser;
