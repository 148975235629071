import React, {FunctionComponent, ReactNode} from 'react';

interface HomeFeatureProps {
    title: ReactNode;
    icon: string;
}

type Props = HomeFeatureProps;

const HomeFeature: FunctionComponent<Props> = ({title, icon}) => {
    return (
        <div className={'feature'}>
            <img src={icon}/>
            <h5>{title}</h5>
        </div>
    );
};

export default HomeFeature;
