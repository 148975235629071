import React, {FunctionComponent, useRef, useState} from 'react';
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import {useAuth} from "../../../../contexts/AuthContext";
import "./DSSettingsUsers.scss";
import Tooltip from "../../../data/Tooltip";
import addIcon from "../../../../assets/icons/material/add.svg";
import DSSettingsAddAccessorModal from "./DSSettingsAddAccessorModal";
import Modal from "../../../data/Modal";
import DSSettingsAccessorEntry from "./DSSettingsAccessorEntry";

const DSSettingsUsers: FunctionComponent = () => {
    const {currentUser} = useAuth();
    const {selectedCamera, cameraSettings, cameraInfo, refreshCameraInfo, refreshCameraSettings} = useDashboardFunc();

    const [addUserModal, setAddUserModal] = useState(false);
    const [addUserHover, setAddUserHover] = useState(false);
    const addUserIconRef = useRef<HTMLImageElement>(null);

    if (!selectedCamera || !cameraSettings || !cameraInfo || !currentUser) {
        return null;
    }

    const admin = cameraSettings.admin;

    return (
        <div className={"camera-settings-users"}>
            <div className={'camera-settings-title-wrapper'}>
                <h3 className={'camera-settings-title'}>Użytkownicy kamery</h3>
                {admin ? (
                    <div className={'icon-holder'} onMouseEnter={() => setAddUserHover(true)}
                         onMouseLeave={() => setAddUserHover(false)}>
                        <img src={addIcon} ref={addUserIconRef} alt={'add user'} className={'title-icon'}
                             onClick={() => setAddUserModal(true)}/>
                        <Tooltip element={addUserIconRef} position={"right"} show={addUserHover}>
                            Dodaj użytkownika
                        </Tooltip>
                    </div>
                ) : null}
            </div>
            <div className={'camera-settings-user-list ' + (admin ? "list-admin" : "list-info")}>
                {cameraInfo.accessors.map((accessor, index) => <DSSettingsAccessorEntry accessor={accessor}
                                                                                        admin={admin}
                                                                                        key={selectedCamera.id + "_" + accessor.id}/>)}
            </div>

            <Modal show={addUserModal} classes={'modal-add-accessor'}>
                <DSSettingsAddAccessorModal onClose={(added) => {
                    setAddUserModal(false);
                    if (added) {
                        refreshCameraInfo();
                        refreshCameraSettings()
                    }
                }}/>
            </Modal>
        </div>
    )
};

export default DSSettingsUsers;
