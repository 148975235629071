import React, {FunctionComponent} from 'react';
import {useDashboard} from "../../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../../contexts/DashboardFuncContext";
import InfoBlock from "../InfoBlock";
import temperatureIcon from "../../../../../assets/icons/material/temperature.svg";
import watchersIcon from "../../../../../assets/icons/material/stream.svg";
import pingIcon from "../../../../../assets/icons/material/ping.svg";
import wifiIcon from "../../../../../assets/icons/material/connection.svg";
import powerIcon from "../../../../../assets/icons/material/power.svg";
import weatherSunIcon from "../../../../../assets/icons/material/weather_sun.svg";
import weatherMoonIcon from "../../../../../assets/icons/material/weather_moon.svg";
import weatherColdIcon from "../../../../../assets/icons/material/weather_cold.svg";
import weatherSnowmanIcon from "../../../../../assets/icons/material/weather_snowman.svg";
import weatherSpringIcon from "../../../../../assets/icons/material/weather_spring.svg";
import weatherAutumnIcon from "../../../../../assets/icons/material/weather_autumn.svg";
import {CameraInfoOnline} from "../../../../../data/camera";
import dayjs from 'dayjs';
import {getDateData} from "../../../../../util/utils";

const DSLiveInfoOnlineNews: FunctionComponent = () => {
    const {subscribe, unsubscribe, send, isConnected} = useDashboard();
    const {selectedCamera, cameraInfo, refreshCameraInfo, streaming} = useDashboardFunc();

    if (!cameraInfo || !selectedCamera || !(cameraInfo instanceof CameraInfoOnline)) {
        return null;
    }

    const workTimeReadable = (cameraInfo.lastLog && cameraInfo.lastLog.type === 'login') ? dayjs(cameraInfo.lastLog.date).fromNow(true) : "Od niedawna";

    const watching = cameraInfo.watchers + (streaming ? 1 : 0);

    const date = new Date();
    const {season, timeOfDay} = getDateData(date);

    let tempIcon: string;
    if (cameraInfo.temperature < -10) {
        tempIcon = weatherSnowmanIcon;
    } else if (cameraInfo.temperature < 0) {
        tempIcon = weatherColdIcon;
    } else if (cameraInfo.temperature < 8) {
        tempIcon = weatherColdIcon;
    } else if (cameraInfo.temperature < 18) {
        if (season === "autumn") {
            tempIcon = weatherAutumnIcon;
        } else if (season === "spring") {
            tempIcon = weatherSpringIcon;
        } else if (season === "summer") {
            if (timeOfDay === "day") {
                tempIcon = weatherColdIcon;
            } else {
                tempIcon = weatherMoonIcon;
            }
        } else {
            tempIcon = weatherSunIcon;
        }
    } else {
        if (timeOfDay === "day") {
            tempIcon = weatherSunIcon;
        } else {
            tempIcon = weatherMoonIcon;
        }
    }

    return (
        <>
            <InfoBlock title={"Temperatura"} icon={temperatureIcon}>
                <img src={tempIcon} className={'temperature-icon'}/>
                <span className={'temperature'}>
                    {cameraInfo.temperature.toFixed(1)}°C
                </span>
            </InfoBlock>
            <InfoBlock title={"Transmisja"} icon={watchersIcon}>
                {watching === 0 ? "Brak oglądających" : (watching === 1 ? "1 oglądający" : (watching + " oglądających"))}
            </InfoBlock>
            <InfoBlock title={"Opóźnienie"} icon={pingIcon}>
                <span
                    className={'ping ' + (cameraInfo.ping < 100 ? "super-fast" : (cameraInfo.ping < 300 ? "fast" : (cameraInfo.ping < 600 ? "medium" : cameraInfo.ping < 1000 ? "slow" : "super-slow")))}>
                    {cameraInfo.ping}ms
                </span>
            </InfoBlock>
            <InfoBlock title={"Połączenie"} icon={wifiIcon}>
                <span
                    className={'ping ' + (cameraInfo.rssi === undefined ? "undefined" : (cameraInfo.rssi >= -55 ? "super-fast" : (cameraInfo.rssi >= -65 ? "fast" : (cameraInfo.rssi >= -75 ? "medium" : cameraInfo.rssi < -85 ? "slow" : "super-slow"))))}>
                    {(cameraInfo.rssi === undefined ? "" : (cameraInfo.rssi >= -55 ? "Bardzo szybkie" : (cameraInfo.rssi >= -65 ? "Szybkie" : (cameraInfo.rssi >= -75 ? "Średnie" : cameraInfo.rssi < -85 ? "Wolne" : "Bardzo wolne"))))}
                </span>
            </InfoBlock>
            <InfoBlock title={"Czas pracy"} icon={powerIcon}>
                {workTimeReadable}
            </InfoBlock>
        </>
    );
};

export default DSLiveInfoOnlineNews;
