import axios, {AxiosError} from 'axios';
import React, {createContext, FunctionComponent, ReactNode, useContext, useState} from 'react';
import {User} from "../data/user";
import {useCookies} from "react-cookie";
import {CookieSetOptions} from 'universal-cookie';
import {useLocation, useNavigate} from "react-router-dom";
import {Base64EncodeUrl} from "../util/utils";

interface AuthProviderProps {
    children: ReactNode
}

type Props = AuthProviderProps;

export interface AuthCookies {
    nick: string;
    token: string;
    color: string;
    admin: boolean;
}

interface AuthContextProps {
    currentUser: User | null,
    register: (nick: string, email: string, password: string) => Promise<{ status: number, error?: number }>,
    login: (nick: string, password: string) => Promise<{ status: number, error?: number }>,
    logout: (requestLogin: boolean) => void,
    refresh: () => Promise<number>,
    getToken: () => string | null,
    getFromCookies: () => AuthCookies | null;
    setUser: (user: User | null) => void;
    setUserColor: (color: string) => void;
}

const AuthContext = createContext<AuthContextProps | null>(null);

export function useAuth() {
    return useContext(AuthContext) || {} as AuthContextProps;
}

export const AuthProvider: FunctionComponent<Props> = ({children}) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [dataUpToDate, setDataUpToDate] = useState<boolean>(false);
    const [cookies, setCookie, removeCookie] = useCookies(['nick', 'token', 'color', 'admin']);
    const navigate = useNavigate();
    const location = useLocation();

    const getToken = (): string | null => {
        if (currentUser && currentUser.token) {
            return currentUser.token;
        }

        return null;
    }

    const getFromCookies = (): AuthCookies | null => {
        return cookies.nick && cookies.token && cookies.color ? {
            nick: cookies.nick,
            token: cookies.token,
            color: cookies.color,
            admin: cookies.admin || false
        } : null;
    }

    //------------------------------------------------------------------------

    const setUser = (user: User | null) => {
        const cookieData: CookieSetOptions = {
            sameSite: 'strict',
            secure: true,
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 90),
            path: '/'
        };

        removeCookie("nick", {path: '/'});
        removeCookie("token", {path: '/'});
        removeCookie("color", {path: '/'});
        removeCookie("admin", {path: '/'});

        if (user) {
            setDataUpToDate(true);
            setCookie('token', user.token, cookieData);
            setCookie('nick', user.nick, cookieData);
            setCookie('color', user.color, cookieData);
            setCookie('admin', user.admin, cookieData);
            setCurrentUser(user);
        } else {
            setDataUpToDate(false);
        }
    }

    const apiServer = process.env.REACT_APP_API_SERVER;

    const register = async (nick: string, email: string, password: string): Promise<{ status: number, error?: number }> => {
        try {
            const response = await axios.post(apiServer + "/register", {
                nick: nick,
                email: email,
                password: password
            });

            if (response.status === 200) {
                const user = User.fromServer(response.data.data);

                if (!user) {
                    return {
                        status: 500
                    };
                }

                setUser(user);
                return {status: 200};
            }

            return {
                status: response.status,
                error: response.data
            };
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response) {
                    return {
                        status: e.response.status,
                        error: e.response.data ? e.response.data['code'] : null
                    };
                }
            }

            return {
                status: 404
            };
        }
    }

    const login = async (nick: string, password: string): Promise<{ status: number, error?: number }> => {
        try {
            const response = await axios.post(apiServer + "/login", {
                nick: nick,
                password: password
            });

            if (response.status === 200) {
                const user = User.fromServer(response.data.data);

                if (!user) {
                    return {
                        status: 500
                    };
                }

                setUser(user);
                return {status: 200};
            }
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response) {
                    return {
                        status: e.response.status,
                        error: e.response.data ? e.response.data['code'] : null
                    };
                }
            }
        }
        return {
            status: 404
        };
    }

    const logout = (requestLogin: boolean = false) => {
        setUser(null);
        if (requestLogin) {
            navigate("/login?redirect=" + Base64EncodeUrl(location.pathname));
        } else {
            navigate("/");
        }
    }

    const refresh = async (): Promise<number> => {
        // here it should have already nick and token in cookies, so there's no need to send it in json
        // if token is not valid, user will get logged out

        const response = await axios.post(apiServer + "/refresh");
        if (response.status === 200) {
            const user = User.fromServer(response.data.data);

            if (!user) {
                setUser(null);
            }

            setUser(user);
        } else {
            setUser(null);
        }

        return response.status;
    }

    const setUserColor = async (color: string): Promise<boolean> => {
        const cookies = getFromCookies();
        if (!cookies) {
            return false;
        }

        try {
            const response = await axios.post(apiServer + "/account/set-color", {
                nick: cookies.nick,
                token: cookies.token,
                color: color
            });
            if (response.status === 200) {
                if (currentUser) {
                    const newUser = currentUser;
                    newUser.color = color;
                    setUser(newUser);
                    console.log("set user")
                }
                return true;
            }
        } catch (e) {
        }
        return false;
    }

    const value: AuthContextProps = {
        currentUser,
        register,
        login,
        logout,
        refresh,
        getToken,
        getFromCookies,
        setUser,
        setUserColor
    }

    return (<AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>);
};

export default AuthContext;
