import React, {FunctionComponent, useRef} from 'react';

interface CheckboxProps {
    value: boolean;
    onChange: (value: boolean) => boolean;
}

type Props = CheckboxProps;

const Checkbox: FunctionComponent<Props> = ({value, onChange}) => {
    const checkboxRef = useRef<HTMLInputElement | null>(null);

    return (
        <label className="switch">
            <input ref={checkboxRef} type="checkbox" onChange={() => {
                if (checkboxRef.current) {
                    const val = checkboxRef.current.checked;
                    if (!onChange(val)) {
                        checkboxRef.current.checked = !val;
                    }
                }
            }} defaultChecked={value}/>
            <span></span>
        </label>
    );
};

export default Checkbox;
