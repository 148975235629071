import React, {FunctionComponent, ReactNode} from 'react';
import {useDashboard} from "../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import errorIcon from "../../../../assets/icons/material/error.svg";
import cameraIcon from "../../../../assets/icons/material/camera.svg";
import menuIcon from "../../../../assets/icons/material/menu.svg";
import {useCameraMenu} from "../../../../contexts/CameraMenuContext";


const DSCameraViewTitle: FunctionComponent<{ children: ReactNode }> = ({children}) => {
    const {subscribe, unsubscribe, send, isConnected} = useDashboard();
    const {selectedCamera} = useDashboardFunc();
    const {toggle} = useCameraMenu();

    return (
        <div className={'camera-context-view-title ' + (selectedCamera === null ? "no-camera" : "")}>
            {selectedCamera === null ? (
                <>
                    <img className={'title-icon'} alt={'X'} src={errorIcon}/>
                    <h2>Nie wybrano kamery<span className={'accent-dot'}>.</span></h2>
                </>
            ) : (
                <>
                    <img className={'title-icon'} alt={'X'} src={cameraIcon}/>
                    <div className={'heading-wrapper'}>
                        <h2>{selectedCamera.name}</h2>
                        <span>{selectedCamera.online ? "online" : "offline"}</span>
                    </div>
                    <div className={'heading-data'}>
                        {children}
                    </div>
                </>
            )}


            <div className={'cameras-menu'}>
                <img src={menuIcon} alt={'Kamery...'} onClick={() => {
                    toggle()
                }}/>
            </div>
        </div>
    );
};

export default DSCameraViewTitle;
