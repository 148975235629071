import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import visibilityOnIcon from "../../assets/icons/material/visibility.svg";
import visibilityOffIcon from "../../assets/icons/material/visibility_off.svg";
import errorIcon from "../../assets/icons/material/error.svg";
import {useForm} from "../../contexts/FormContext";
import Tooltip from "../data/Tooltip";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {OnBlurComponent} from "../../util/blur";

interface InputFieldProps {
    name: string;
    title: string;
    icon: string;
    type: InputType;
    value?: string;
    required?: boolean;
    autofill?: boolean;
}

class InputType {
    public type: string;
    public acceptChar: (char: string) => boolean;
    public isValid: (text: string) => string | true;

    constructor(type: string, acceptChar: (char: string) => boolean, isValid: (text: string) => string | true) {
        this.type = type;
        this.acceptChar = acceptChar;
        this.isValid = isValid;
    }
}

export const InputTypes = {
    nick: new InputType("text", (char) => {
        const allowed = "abcdefghijklmnopqrstuwvxyz1234567890_";
        return allowed.includes(char.toLowerCase());
    }, (text) => {
        if (text.length < 3) {
            return "Nazwa powinna składać się z co najmniej 3 znaków."
        }
        if (text.length > 50) {
            return "Nazwa powinna składać się maksymalnie z 50 znaków."
        }

        return true;
    }),
    sid: new InputType("text", (char) => {
        const allowed = "abcdefghijklmnopqrstuwvxyz1234567890";
        return allowed.includes(char.toLowerCase());
    }, (text) => {
        if (text.length !== 16) {
            return "SID musi składać się z 16 znaków."
        }

        return true;
    }),
    hash: new InputType("text", (char) => {
        const allowed = "abcdefghijklmnopqrstuvwxyz1234567890";
        return allowed.includes(char.toLowerCase());
    }, (text) => {
        if (text.length !== 6) {
            return "Hash musi składać się z 6 znaków."
        }

        return true;
    }),
    email: new InputType("email", (char) => {
        return true;
    }, (text) => {
        if (!text.match(new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"))) {
            return "Email jest niepoprawny"
        }
        if (text.length > 255) {
            return "Email powienien składać się maksymalnie z 255 znaków";
        }

        return true;
    }),
    password: new InputType("password", (char) => {
        return true;
    }, (text) => {
        if (text.length <= 8) {
            return "Hasło powinno składać się z co najmniej 8 znaków."
        }
        if (text.length > 64) {
            return "Hasło powinno składać się maksymalnie z 64 znaków."
        }

        return true;
    })
}

type Props = InputFieldProps;

const InputField: FunctionComponent<Props> = ({
                                                  name,
                                                  title,
                                                  icon,
                                                  type,
                                                  value = "",
                                                  required = true,
                                                  autofill = true
                                              }) => {
    const {onSubmit, onValueGetter, onInvalidValueSet} = useForm();
    const {height, width} = useWindowDimensions();

    const focusRef = useRef(false);

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [realValue, setRealValue] = useState(value); // value typed in the field
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [hasFocus, setHasFocus] = useState(false);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [realType, setRealType] = useState(type.type); // used for password visibility
    const [realTimeCheck, setRealTimeCheck] = useState(false); // after submit, if value can be validated with user typing
    const invalidValues = useRef<{ [key: string]: string }>({}); // invalid values e.g nick that already exists

    const input = useRef<HTMLInputElement>(null);
    const iconRef = useRef<HTMLImageElement>(null);

    function setValue(value: string) {
        setRealValue(value);
        if (realTimeCheck) {
            validate(value);
        }
    }

    function addInvalidValue(value: string, errorMsg: string) {
        invalidValues.current[value] = errorMsg;
        validate(realValue);
        forceUpdate();
    }

    function clearInvalidValues() {
        invalidValues.current = {};
        forceUpdate();
    }

    function validate(value: string, canFocus: boolean = false): boolean {
        const validityMsg = type.isValid(value);

        if (required && value === "") {
            setErrorMessage('To pole jest wymagane.');
            return false;
        }

        if (!required || value !== "") {
            if (validityMsg !== true) {
                setErrorMessage(validityMsg);
                return false;
            }
        }

        for (let key in invalidValues.current) {
            if (value === key) {
                setErrorMessage(invalidValues.current[key]);
                return false;
            }
        }

        for (let i = 0; i < value.length; i++) {
            let char = value[i];
            if (!type.acceptChar(char)) {
                setErrorMessage("Nieprawidłowy znak '" + char + "'");
                return false;
            }
        }

        setErrorMessage(null);
        return true;
    }

    useEffect(() => {
        onSubmit(name, () => {
            setRealTimeCheck(true);
            return validate(input.current?.value || realValue);
        });
    }, [name, realValue])

    useEffect(() => {
        onValueGetter(name, () => {
            return {
                value: input.current?.value || realValue,
                error: errorMessage
            };
        })
    }, [name, realValue, errorMessage]);

    useEffect(() => {
        onInvalidValueSet(name, (value, error) => {
            addInvalidValue(value, error);
        })
    }, [name])

    useEffect(() => {
        let interval = setInterval(() => {
            if (input.current) {
                setValue(input.current.value)
                clearInterval(interval)
            }
        }, 100);

        return () => {
            clearInterval(interval);
        }
    })

    const onBlur = () => {
        setTimeout(() => {
            if (!focusRef.current) {
                clearInvalidValues();
            }
        }, 250)
    }

    const showEyeButton = type.type === "password";

    return (
        <OnBlurComponent onBlur={() => {
            focusRef.current = false;
            onBlur()
        }}>
            <div className={"input-field " + (hasFocus ? "focus" : "") + " " + (errorMessage ? "invalid" : "")}
                 onClick={() => {
                     if (!hasFocus && input.current) input.current.focus()
                 }}>
                <div className={"input-holder"}>
                <span className={"title"}>
                    {title}
                </span>
                    <input ref={input} type={realType} name={name} defaultValue={value}
                           onFocus={() => {
                               focusRef.current = true;
                               setHasFocus(true)
                           }}
                           onBlur={() => setHasFocus(false)} onChange={(e) => {
                        setValue(e.target.value)
                    }} autoCapitalize={autofill ? "on" : "off"} autoCorrect={autofill ? "on" : "off"}
                           autoComplete={autofill ? "on" : "new-password"}/>
                </div>
                {showEyeButton && (
                    <img src={isPasswordVisible ? visibilityOffIcon : visibilityOnIcon} alt={"(+)"}
                         className={"password-visibility"} onClick={() => {
                        setPasswordVisible(!isPasswordVisible);
                        setRealType(isPasswordVisible ? "password" : "text");
                    }}/>
                )}
                <img ref={iconRef} src={icon} alt={""} className={'icon ' + (errorMessage == null ? "" : "invalid")}/>
                <Tooltip element={iconRef} position={(width == null || width > 900 ? "right" : "bottom_left")}
                         show={errorMessage != null && hasFocus}>
                    <img src={errorIcon} alt={'[!]'} className={'tooltip-icon'}/>
                    <span className={'tooltip-span'}>{errorMessage}</span>
                </Tooltip>
            </div>
        </OnBlurComponent>
    );
};

export default InputField;
