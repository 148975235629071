import React, {FunctionComponent} from 'react';
import closeIcon from './../../../../../assets/icons/material/close.svg';
import saveIcon from './../../../../../assets/icons/material/save.svg';
import "./DSSettingsSaveModal.scss";
import Button from "../../../../forms/Button";

interface SaveModalProps {
    onClose: () => void;
    onAction: (action: "save" | "revert") => void;
    changedSettings: { [key: string]: any };
}

type Props = SaveModalProps;
const DSSettingsSaveModal: FunctionComponent<Props> = ({onClose, onAction, changedSettings}) => {

    const changedArray: { name: string, value: string }[] = [];
    for (let changedSettingsKey in changedSettings) {
        changedArray.push({name: changedSettingsKey, value: changedSettings[changedSettingsKey]});
    }

    return (<>
        <div className={'modal-save-title'}>
            <img src={saveIcon} alt={'save'} className={'modal-title-icon'}/>
            <h2 className={'modal-title'}>Zapisz ustawienia<span className={'accent-dot'}>.</span></h2>
            <img src={closeIcon} alt={'close'} className={'modal-close-icon'} onClick={() => onClose()}/>
        </div>
        <div className={'modal-save-data'}>
            <h3>Czy chcesz zapisać poniższe ustawienia?</h3>
            <div className={'settings-list'}>
                {changedArray.map(({name, value}) => (
                    <div className={'setting-row'} key={name}>
                        <span className={'name'}>
                            {name}
                        </span>
                        <span className={'value'}>
                            {value}
                        </span>
                    </div>
                ))}
            </div>
            <p className={'restart-info'}>Kamera uruchomi się ponownie.</p>
            <div className={'buttons'}>
                <Button onClick={() => {
                    onClose();
                    onAction("save");
                }} type={"filled"}>
                    <span className={'text'}>Zapisz</span>
                </Button>
                <Button onClick={() => {
                    onClose();
                }} type={"outlined"}>
                    <span className={'text'}>Anuluj</span>
                </Button>
                <Button onClick={() => {
                    onClose();
                    onAction("revert");
                }} type={"text"}>
                    <span className={'text'}>Resetuj</span>
                </Button>
            </div>
        </div>
    </>);
};

export default DSSettingsSaveModal;
