import React, {FunctionComponent} from 'react';
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import adminIcon from "../../../../assets/icons/material/admin.svg";
import "./DSSettingsTitle.scss";

const DSSettingsTitle: FunctionComponent = () => {
    const {selectedCamera, cameraSettings, cameraInfo} = useDashboardFunc();

    if (!selectedCamera || !cameraSettings || !cameraInfo) {
        return null;
    }

    return (
        <div className={"camera-settings-title"}>
            {cameraSettings.admin ? (
                <div className={'admin-mode'}>
                    <img src={adminIcon} alt={'admin'}/>
                    <p>Tryb administratora</p>
                </div>
            ) : null}
        </div>
    );
};

export default DSSettingsTitle;
