import React, {FunctionComponent} from 'react';
import {Camera} from "../../../../data/camera";
import {useDashboard} from "../../../../contexts/DashboardContext";
import DSCameraPreview from "./DSCameraPreview";
import "./DSCameraList.scss";
import Button from "../../../forms/Button";
import Spinner from "../../../util/Spinner";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";

interface CameraListProps {
    onCameraSelect: (camera: Camera) => void;
    onCameraAdd: () => void;
}

type Props = CameraListProps;

const DSCameraList: FunctionComponent<Props> = ({onCameraSelect, onCameraAdd}) => {
    const {subscribe, unsubscribe, send, isConnected} = useDashboard();
    const {selectedCamera, selectCamera, cameras} = useDashboardFunc();

    const onSelect = (camera: Camera) => {
        selectCamera(camera);
        onCameraSelect(camera);
    }

    return (
        <div className={'camera-list'}>
            {cameras != null && cameras.map((camera) => {
                return (<DSCameraPreview key={camera.id} camera={camera} onSelect={() => onSelect(camera)}/>)
            })}
            {cameras != null && cameras.length === 0 && (
                <div className={'no-cameras'}>
                    <h3>Nie znaleziono kamer</h3>
                    <Button onClick={() => {
                        onCameraAdd();
                    }}>
                        <span className={'text'}>Dodaj nową</span>
                    </Button>
                </div>
            )}
            {cameras == null && (
                <div className={'loading-cameras'}>
                    <Spinner type={"primary"}></Spinner>
                </div>
            )}
        </div>
    );
};

export default DSCameraList;
