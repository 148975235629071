import React, {FunctionComponent} from 'react';
import {picNameToDate} from "../../../../util/utils";
import dayjs from 'dayjs';
import imageIcon from "../../../../assets/icons/material/image.svg";
import linkIcon from "../../../../assets/icons/material/link.svg";
import {useNavigate} from "react-router-dom";

interface LastPictureProps {
    name: string;
}

type Props = LastPictureProps;

const DSLiveLastPicture: FunctionComponent<Props> = ({name}) => {
    const navigate = useNavigate();

    const date = picNameToDate(name);
    if (!date) {
        return null;
    }


    return (
        <div className={'last-picture'} onClick={() => {
            navigate("/dashboard/history/?sel=" + name)
        }}>
            <img src={imageIcon} className={'img-picture'} alt={'pic'}/>
            <p className={'pic-date'}>
                {dayjs(date).fromNow()}
            </p>
            <img src={linkIcon} className={'img-link'} alt={'link'}/>
        </div>
    );
};

export default DSLiveLastPicture;
