import React, {createContext, FunctionComponent, ReactNode, useContext, useState} from 'react';
import "./AlertContext.scss";

interface CameraMenuContextProvider {
    children: ReactNode;
}

type Props = CameraMenuContextProvider;

export interface CameraMenuContextProps {
    shown: boolean | null;
    toggle: () => void;
}

const CameraMenuContext = createContext<CameraMenuContextProps | null>(null);

export function useCameraMenu() {
    return useContext(CameraMenuContext) || {} as CameraMenuContextProps;
}

const CameraMenuProvider: FunctionComponent<Props> = ({children}) => {
    const [menuShown, setMenuShown] = useState<boolean | null>(null);

    const toggle = () => {
        setMenuShown(!menuShown)
    }

    const shown = menuShown;

    const contextProps: CameraMenuContextProps = {
        shown,
        toggle
    }

    return (
        <CameraMenuContext.Provider value={contextProps}>
            {children}
        </CameraMenuContext.Provider>
    );
};

export default CameraMenuProvider;
