import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import "./Account.scss";
import {User} from "../../data/user";
import axios, {AxiosError} from "axios";
import {AuthCookies, useAuth} from "../../contexts/AuthContext";
import Spinner from "../../components/util/Spinner";
import dayjs from "dayjs";
import Button from "../../components/forms/Button";
import Tooltip from "../../components/data/Tooltip";
import {OnBlurComponent} from "../../util/blur";
import refreshIcon from "../../assets/icons/material/refresh.svg";
import {Camera} from "../../data/camera";
import AccountCameraPreview from "../../components/account/AccountCameraPreview";
import AccountLoginComponent from "../../components/account/AccountLoginComponent";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/data/Modal";
import DSCameraAddModal from "../../components/dashboard/content/router/DSCameraAddModal";
import addIcon from "../../assets/icons/material/add.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import useProtected from "../../hooks/useProtected";


export type AccountLogin = { id: number, user_id: number, ip: string, date: Date };

class AccountData {
    public readonly user: User;
    public readonly availableColors: { name: string, hex: string }[];
    public readonly cameras: Camera[];
    public readonly logins: AccountLogin[];

    constructor(user: User, availableColors: { name: string, hex: string }[], cameras: Camera[], logins: AccountLogin[]) {
        this.user = user;
        this.availableColors = availableColors;
        this.cameras = cameras;
        this.logins = logins;
    }

    public static fromServer(data: any): AccountData | null {
        if (!data.hasOwnProperty('user')) {
            return null;
        }
        if (!data.hasOwnProperty('available_colors')) {
            return null;
        }
        if (!data.hasOwnProperty('cameras')) {
            return null;
        }
        if (!data.hasOwnProperty('logins')) {
            return null;
        }
        const user = User.fromServer(data['user']);
        if (!user) {
            return null;
        }
        const cameras = [];
        for (let cam of data['cameras']) {
            const camera = Camera.fromServer(cam);
            if (camera) {
                cameras.push(camera);
            }
        }

        return new AccountData(user, data['available_colors'], cameras, data['logins']);
    }
}

const apiServer = process.env.REACT_APP_API_SERVER;
const fetchUser = (auth: AuthCookies, callback: (user: AccountData | number | null) => void) => {
    try {
        axios.post(apiServer + "/account", {
            nick: auth.nick,
            token: auth.token
        }).then((response) => {
            if (response.status === 200) {
                callback(AccountData.fromServer(response.data));
            } else {
                callback(response.status);
            }
        }).catch(e => {
            if(e instanceof AxiosError) {
                callback(e.response?.status || null);
            }
        })
    } catch (e) {
        callback(null);
    }
}
const generateHash = (auth: AuthCookies, callback: (hash: string | null) => void) => {
    try {
        axios.post(apiServer + "/account/generate-hash", {
            nick: auth.nick,
            token: auth.token
        }).then((response) => {
            if (response.status === 200) {
                callback(response.data['hash']);
            } else {
                callback(null);
            }
        })
    } catch (e) {
        callback(null);
    }
}

const Account: FunctionComponent = () => {
    useProtected();
    const {setUser, getFromCookies, setUserColor, logout} = useAuth();
    const cookies = getFromCookies();
    const navigate = useNavigate();

    const [userData, setUserData] = useState<AccountData | null>(null);
    const [colorButtonActive, setColorButtonActive] = useState(false);
    const [userColorCache, setUserColorCache] = useState(cookies?.color);
    const [addCameraModal, setAddCameraModal] = useState(false);
    const [addCameraHover, setAddCameraHover] = useState(false);

    const [hashLoading, setHashLoading] = useState(false);

    const colorRef = useRef<HTMLDivElement>(null);
    const addCameraIconRef = useRef<HTMLImageElement>(null);

    useDocumentTitle("MCam | Konto");


    useEffect(() => {
        if (!cookies) {
            return;
        }

        refresh();
    }, []);

    const refresh = () => {
        if (!cookies) {
            return;
        }
        setUserData(null);

        fetchUser(cookies, (user) => {
            if (user instanceof AccountData) {
                setUser(user.user);
                setUserData(user);
            } else {
                if(user === 401) {
                    logout(true);
                }
            }
        })
    }

    if (!cookies) {
        return null;
    }

    return (
        <div className={'account-wrapper'}>
            <div className={'account-background'}>
                <div className={'account-background-stroke'}/>
            </div>
            <div className={'account-content-wrapper'}>
                <OverlayScrollbarsComponent
                    options={{overflowBehavior: {x: "hidden", y: "scroll"}, scrollbars: {autoHide: "scroll"}}}>
                    <div className={'account-content ' + (userData ? "" : "loading")}>
                        <div className={'account-header'}>
                            <h1>Moje konto<span className={'accent-dot'}>.</span></h1>
                            <img src={refreshIcon} alt={'refresh'} className={'refresh-img'} onClick={() => {
                                refresh()
                            }}/>
                        </div>
                        {!userData ? (<div className={'loader'}><Spinner type={'primary'}/></div>) : (
                            <>
                                <div className={'account-box'}>
                                    <div className={'avatar-box'}>
                                        <div className={'avatar-background'}
                                             style={{backgroundColor: '#' + userColorCache}}/>
                                        <img className={'avatar'} src={userData.user.getAvatar()} alt={'avatar'}/>
                                    </div>
                                    <div className={'data-box'}>
                                        <h3>{userData.user.nick}</h3>
                                        <h4>{userData.user.email}</h4>
                                        <p className={'join-date'}>Dołączył {dayjs(userData.user.joinDate).fromNow()}</p>
                                    </div>
                                    <div className={'data-secondary-box'}>
                                        <div className={'hash-box'}>
                                            {hashLoading ? <Spinner type={'primary'}/> : (
                                                <h3>{userData.user.hash != null ? (userData.user.hash.toUpperCase().substring(0, 3) + " " + userData.user.hash.toUpperCase().substring(3, 6)) : null}</h3>
                                            )}
                                        </div>
                                        <div className={'logout-box'}>
                                            <Button onClick={() => {
                                                logout(false);
                                            }} type={'outlined'}><span className={'text'}>Wyloguj się</span></Button>
                                        </div>
                                    </div>
                                </div>
                                <div className={'divider'}/>
                                <div className={'account-info'}>
                                    <section>
                                        <p className={'section-title'}>
                                            Data utworzenia
                                        </p>
                                        <span className={'label'}>
                                    {dayjs(userData.user.joinDate).format('YYYY-MM-DD')}
                                </span>
                                    </section>
                                    <section>
                                        <p className={'section-title'}>
                                            Kolor
                                        </p>
                                        <div className={'inline'}>
                                            <div className={'color-label'}
                                                 style={{backgroundColor: '#' + userColorCache}}/>
                                            <OnBlurComponent onBlur={() => {
                                                setColorButtonActive(false);
                                            }}>
                                                <div className={'button-tooltip-wrapper'} ref={colorRef} tabIndex={0}>
                                                    <Button onClick={() => {
                                                        setColorButtonActive(!colorButtonActive)
                                                    }}><span className={'text'}>Zmień kolor</span></Button>
                                                    <Tooltip element={colorRef} position={"right"}
                                                             show={colorButtonActive}
                                                             classes={"color-tooltip"} centered={false} arrow={false}>
                                                        <h3>Zmień kolor</h3>
                                                        <div className={'colors'}>
                                                            {userData.availableColors.map((color) => (
                                                                <div key={color.hex}
                                                                     className={'color ' + (color.hex === userData.user.color ? "selected" : "")}
                                                                     onClick={() => {
                                                                         if (color.hex !== userData.user.color) {
                                                                             // send
                                                                             setUserColorCache(color.hex);
                                                                             setUserColor(color.hex);
                                                                             setColorButtonActive(false);
                                                                         }
                                                                     }}>
                                                                    <div className={'color-square'}
                                                                         style={{backgroundColor: '#' + color.hex}}/>
                                                                    <span className={'color-name'}>{color.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </OnBlurComponent>
                                        </div>
                                    </section>
                                    <section>
                                        <p className={'section-title'}>
                                            Hasło
                                        </p>
                                        <Button onClick={() => {
                                        }}><span className={'text'}>Zmień hasło</span></Button>
                                    </section>
                                    <section>
                                        <p className={'section-title'}>
                                            Awatar
                                        </p>
                                        <Button onClick={() => {
                                        }}><span className={'text'}>Zmień awatar</span></Button>
                                    </section>
                                    <section>
                                        <p className={'section-title'}>
                                            Hash
                                        </p>
                                        <div className={'inline'}>
                                            <span className={'hash'}>{userData.user.hash?.toUpperCase()}</span>
                                            <Button onClick={() => {
                                                setHashLoading(true);
                                                generateHash(cookies, (hash) => {
                                                    setHashLoading(false);
                                                    if (hash) {
                                                        refresh();
                                                    }
                                                })
                                            }}>{hashLoading ? <Spinner type={'background'}/> :
                                                <span className={'text'}>Generuj nowy</span>}</Button>
                                        </div>
                                    </section>
                                </div>
                                <div className={'divider'}/>
                                <div className={'account-cameras'}>
                                    <div className={'div-title-wrapper'}>
                                        <h3 className={'div-title'}>Moje kamery</h3>
                                        <div className={'icon-holder'} onMouseEnter={() => setAddCameraHover(true)}
                                             onMouseLeave={() => setAddCameraHover(false)}>
                                            <img src={addIcon} ref={addCameraIconRef} alt={'add camera'}
                                                 className={'title-icon'} onClick={() => setAddCameraModal(true)}/>
                                            <Tooltip element={addCameraIconRef} position={"right"}
                                                     show={addCameraHover}>
                                                Dodaj kamerę
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {userData.cameras.length === 0 ? (
                                        <div className={'account-no-cameras'}><p>Nie posiadasz kamer</p><Button
                                            onClick={() => {
                                                setAddCameraModal(true);
                                            }}>Dodaj kamerę</Button></div>) : (
                                        <div className={'account-camera-list'}>
                                            {userData.cameras.map((camera) => (
                                                <AccountCameraPreview key={camera.id} camera={camera} onSelect={() => {
                                                    navigate("/dashboard/?camera=" + camera.sid)
                                                }}/>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className={'divider'}/>
                                <div className={'account-logins'}>
                                    <h3 className={'div-title'}>Ostatnie logowania</h3>
                                    <div className={'account-login-list'}>
                                        {userData.logins.map((login, index) => (
                                            <AccountLoginComponent login={login} key={login.id} first={index === 0}/>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}


                        <Modal show={addCameraModal} classes={"modal-add-camera"}>
                            <DSCameraAddModal onClose={(added) => {
                                setAddCameraModal(false);
                                if (added) {
                                    refresh();
                                }
                            }}/>
                        </Modal>
                    </div>
                </OverlayScrollbarsComponent>
            </div>
        </div>
    );
};

export default Account;
