import React, {FunctionComponent, useRef, useState} from 'react';
import "./Login.scss";
import userIcon from "../../assets/icons/material/user.svg";
import emailIcon from "../../assets/icons/material/email.svg";
import passwordIcon from "../../assets/icons/material/password.svg";
import InputField, {InputTypes} from "../../components/forms/InputField";
import Button from "../../components/forms/Button";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import FormProvider, {ValueHandlerProps} from "../../contexts/FormContext";
import Spinner from "../../components/util/Spinner";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {Base64DecodeUrl} from "../../util/utils";

interface LoginProps {
    type: "login" | "register";
}

type Props = LoginProps;

const Login: FunctionComponent<Props> = ({type}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useDocumentTitle("MCam | " + (type === "login" ? "Logowanie" : "Rejestracja"));
    const redirectPath = searchParams.get("redirect");

    const [loginSubmit, setLoginSubmit] = useState<() => boolean>(() => {
        return () => false
    });
    const [registerSubmit, setRegisterSubmit] = useState<() => boolean>(() => {
        return () => false
    });
    const [loginGetter, setLoginGetter] = useState<(() => ValueHandlerProps | null)>(() => {
        return () => null
    });
    const [registerGetter, setRegisterGetter] = useState<(() => ValueHandlerProps | null)>(() => {
        return () => null
    });
    const [loginInvalidSetter, setLoginInvalidSetter] = useState<(((name: string, invalidValue: string, error: string) => void) | null)>(() => {
        return null
    });
    const [registerInvalidSetter, setRegisterInvalidSetter] = useState<(((name: string, invalidValue: string, error: string) => void) | null)>(() => {
        return null
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const bgRef = useRef<HTMLDivElement>(null);
    const strokeRef = useRef<HTMLDivElement>(null);

    const {login, register} = useAuth();

    const submitHandler = (type: "login" | "register", handler: () => boolean) => {
        if (type === "login") {
            setLoginSubmit(() => handler);
        } else {
            setRegisterSubmit(() => handler)
        }
    }
    const getterHandler = (type: "login" | "register", handler: () => ValueHandlerProps) => {
        if (type === "login") {
            setLoginGetter(() => handler);
        } else {
            setRegisterGetter(() => handler)
        }
    }
    const invalidSetHandler = (type: "login" | "register", handler: (name: string, invalidValue: string, error: string) => void) => {
        if (type === "login") {
            setLoginInvalidSetter(() => handler);
        } else {
            setRegisterInvalidSetter(() => handler);
        }
    }

    const submit = (type: "login" | "register") => {
        if (type === "login") {
            if (!loginSubmit || !loginSubmit()) {
                return
            }

            const getter = loginGetter();
            if (!getter) {
                return;
            }

            const nick = getter['nick'].value;
            const password = getter['password'].value;

            if (!nick || !password) {
                return;
            }

            setLoading(true);
            (async () => {
                setErrorMessage(null);
                const response = await login(nick, password);
                if (response.status === 200) {
                    redirect()
                } else {
                    switch (response.status) {
                        case 400:
                            setErrorMessage("Nieprawidłowe żadanie. Spróbuj jeszcze raz.")
                            break
                        case 401:
                            if (response.error != null && response.error !== 0 && loginInvalidSetter) {
                                if (response.error === -1) {
                                    loginInvalidSetter("nick", nick, "Użytkownik nie istnieje.")
                                } else if (response.error === -2) {
                                    loginInvalidSetter("password", password, "Hasło jest nieprawidłowe.");
                                }

                                break
                            }

                            setErrorMessage("Błąd serwera. Spróbuj jeszcze raz.")
                            break
                        case 500:
                            setErrorMessage("Błąd w przetwarzaniu danych. Spróbuj jeszcze raz.")
                            break
                        default:
                            setErrorMessage("Błąd " + response.status + ". Spróbuj jeszcze raz.")
                            break
                    }
                }
                setLoading(false);
            })();
        } else {
            if (!registerSubmit || !registerSubmit()) {
                return
            }

            const getter = registerGetter();
            if (!getter) {
                return;
            }

            const nick = getter['nick'].value;
            const email = getter['email'].value;
            const password = getter['password'].value;

            if (!nick || !email || !password) {
                return;
            }

            setLoading(true);
            (async () => {
                setErrorMessage(null);
                const response = await register(nick, email, password);
                if (response.status === 200) {
                    redirect()
                } else {
                    switch (response.status) {
                        case 400:
                            setErrorMessage("Nieprawidłowe żadanie. Spróbuj jeszcze raz.")
                            break
                        case 401:
                            if (response.error != null && response.error !== 0 && registerInvalidSetter) {
                                if (response.error === -1) {
                                    registerInvalidSetter("nick", nick, "Ta nazwa użytkownika jest już zajęta.")
                                } else if (response.error === -2) {
                                    registerInvalidSetter("email", email, "Email jest już w użyciu. <NavLink to={'/login'}>Zaloguj się</NavLink>")
                                } else if (response.error === -3) {
                                    registerInvalidSetter("password", password, "Hasło jest niepoprawne. Wymyśl inną kombinację");
                                }

                                break
                            }

                            setErrorMessage("Błąd serwera. Spróbuj jeszcze raz.")
                            break
                        case 500:
                            setErrorMessage("Błąd w przetwarzaniu danych. Spróbuj jeszcze raz.")
                            break
                        default:
                            setErrorMessage("Błąd " + response.status + ". Spróbuj jeszcze raz.")
                            break
                    }
                }
                setLoading(false);
            })();
        }
    }

    const redirect = () => {
        navigate(redirectPath ? Base64DecodeUrl(redirectPath) : "/dashboard/");
    }

    return (
        <>
            <div ref={bgRef} className={"login-background"}></div>
            <div ref={strokeRef} className={"login-stroke"}></div>

            <div className={"wrapper"}>
                <div className={"content"}>
                    {type === "login" ? (
                        <>
                            <h3>{searchParams.has("redirect") ? "Sesja wygasła" : "Logowanie"}</h3>
                            <h1>Zaloguj się<span className={"accent-dot"}>.</span></h1>
                            <p className={"change-method"}>Nie masz konta? <NavLink to={'/register'}>Zarejestruj
                                się</NavLink></p>

                            <FormProvider key={'l_form'} submitHandler={(submit) => {
                                return submitHandler(type, submit)
                            }} valueHandler={(getter) => {
                                return getterHandler(type, getter)
                            }} invalidSetHandler={(setter) => {
                                return invalidSetHandler(type, setter)
                            }}>
                                <InputField key={'l_nick'} name={'nick'} title={"Nazwa użytkownika"} icon={userIcon}
                                            type={InputTypes.nick}
                                ></InputField>
                                <InputField key={'l_password'} name={'password'} title={"Hasło"} icon={passwordIcon}
                                            type={InputTypes.password}
                                ></InputField>
                                <div className={"buttons"}>
                                    <Button type={"filled"} onClick={() => {
                                        submit(type)
                                    }}>
                                        {loading ? (
                                            <Spinner type={'background'}></Spinner>
                                        ) : (<span className={'text'}>Zaloguj się</span>)}
                                    </Button>
                                </div>
                            </FormProvider>

                            <div className={"error-message"}>
                                {errorMessage && (<span>{errorMessage}</span>)}
                            </div>
                        </>
                    ) : (
                        <>
                            <h3>Rejestracja</h3>
                            <h1>Stwórz nowe konto<span className={"accent-dot"}>.</span></h1>
                            <p className={"change-method"}>Masz już konto? <NavLink to={'/login'}>Zaloguj się</NavLink>
                            </p>

                            <FormProvider key={'r_form'} submitHandler={(submit) => {
                                submitHandler(type, submit)
                            }} valueHandler={(getter) => {
                                getterHandler(type, getter)
                            }} invalidSetHandler={(setter) => {
                                return invalidSetHandler(type, setter)
                            }}>
                                <InputField key={'r_nick'} name={'nick'} title={"Nazwa użytkownika"} icon={userIcon}
                                            type={InputTypes.nick}
                                ></InputField>
                                <InputField key={'r_email'} name={'email'} title={"Email"} icon={emailIcon}
                                            type={InputTypes.email}
                                ></InputField>
                                <InputField key={'r_password'} name={'password'} title={"Hasło"} icon={passwordIcon}
                                            type={InputTypes.password}
                                ></InputField>
                                <div className={"buttons"}>
                                    <Button type={"filled"} onClick={() => {
                                        submit(type)
                                    }}>
                                        {loading ? (
                                            <Spinner type={'background'}></Spinner>
                                        ) : (<span className={'text'}>Zarejestruj się</span>)}
                                    </Button>
                                </div>
                            </FormProvider>

                            <div className={"error-message"}>
                                {errorMessage && (<span>{errorMessage}</span>)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Login;
