import React, {FunctionComponent, useEffect, useRef} from 'react';
import Spinner from "../../../../util/Spinner";
import offlineIcon from "../../../../../assets/icons/material/offline.svg";
import "./DSLiveModal.scss";
import playIcon from "../../../../../assets/icons/material/play.svg";
import pauseIcon from "../../../../../assets/icons/material/pause.svg";
import closeIcon from "../../../../../assets/icons/material/close.svg";
import {Messages} from "../../../../../data/messages";
import {useDashboard} from "../../../../../contexts/DashboardContext";

interface LiveModalProps {
    open: boolean;
    online: boolean;
    actionButtonState: "play" | "pause";
    toggleStream: () => void;
    lastFrame: string;
    onClose: () => void;
    getFps: () => number | null;
}

type Props = LiveModalProps;

const DSLiveModal: FunctionComponent<Props> = ({
                                                   open,
                                                   online,
                                                   actionButtonState,
                                                   toggleStream,
                                                   lastFrame,
                                                   getFps,
                                                   onClose
                                               }) => {
    const {subscribe, unsubscribe} = useDashboard();

    const liveFrameRef = useRef<HTMLImageElement>(null);
    const spinnerRef = useRef<HTMLDivElement>(null);
    const fpsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!open) {
            return;
        }

        const sub = subscribe(Messages.RESPOND_STREAM, (data) => {
            if (liveFrameRef.current && open) {
                let bytes = new Uint8Array(data);
                let binary = '';
                let len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i])
                }

                const image = "data:image/jpg;base64," + window.btoa(binary);
                liveFrameRef.current.src = image;
            }
        });

        const interval = setInterval(() => {
            if (fpsRef.current) {
                fpsRef.current.innerText = getFps() + "fps";
            }
        }, 1000);

        return () => {
            unsubscribe(sub);
            clearInterval(interval)
        }
    }, [open]);

    useEffect(() => {
        if (fpsRef.current) {
            if (actionButtonState === "pause") {
                fpsRef.current.innerHTML = "";
            }
        }
    }, [actionButtonState])

    return (
        <div className={'modal-live-wrapper'}>
            <div className={'modal-live-title-wrapper'}>
                <span className={'modal-live-data'} ref={fpsRef}>

                </span>
                <div className={'modal-live-buttons'}>
                    <img src={actionButtonState === "play" ? playIcon : pauseIcon} alt={'play/pause'}
                         className={'icon-btn'} onClick={() => toggleStream()}/>
                    <img src={closeIcon} alt={'close'} className={'icon-btn'}
                         onClick={() => onClose()}/>
                </div>
            </div>
            <div className={'spinner-holder hide'} ref={spinnerRef}><Spinner type={"background"}/></div>
            {online ? null : (
                <div className={'offline-info'}>
                    <img src={offlineIcon} alt={''}/>
                    <span>offline</span>
                </div>
            )}
            <img ref={liveFrameRef} src={lastFrame} className={'modal-live-fullscreen ' + (online ? "" : "offline")}
                 onClick={() => toggleStream()}/>
        </div>
    );
};

export default DSLiveModal;
