import React, {FunctionComponent, ReactNode, useEffect, useRef, useState} from 'react';
import "./Tooltip.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";

type TooltipPosition = "right" | "bottom_left" | "left";

interface TooltipProps {
    element: React.RefObject<HTMLElement>;
    children: ReactNode;
    position: TooltipPosition;
    centered?: boolean;
    show: boolean;
    classes?: string;
    arrow?: boolean;
}

type Props = TooltipProps;

const screenPadding = 10;

const Tooltip: FunctionComponent<Props> = ({
                                               children,
                                               element,
                                               position,
                                               centered = true,
                                               show,
                                               classes = "",
                                               arrow = true
                                           }) => {
    const [screenPos, setScreenPos] = useState<{ top: number, left: number }>({top: -9999, left: -9999});
    const windowDimensions = useWindowDimensions();

    const tooltip = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (show) {
                let el = element.current;
                const boundingBox = element.current?.getBoundingClientRect();
                const tooltipBox = tooltip.current?.getBoundingClientRect();

                if (el && boundingBox && tooltipBox && tooltipBox) {
                    let top = boundingBox.top;
                    let left = boundingBox.left;

                    if (position === "right") {
                        left += boundingBox.width;

                        if (centered) {
                            let tooltipCenter = tooltipBox.height / 2;
                            let boundingCenter = boundingBox.height / 2;

                            if (tooltipCenter > boundingCenter) {
                                top += boundingCenter - tooltipCenter;
                            } else {
                                top -= tooltipCenter - boundingCenter;
                            }
                        }
                    } else if (position === "bottom_left") {
                        left -= tooltipBox.width;
                        left += 25;

                        top += boundingBox.height;
                    } else if (position === "left") {
                        left -= tooltipBox.width;

                        if (centered) {
                            let tooltipCenter = tooltipBox.height / 2;
                            let boundingCenter = boundingBox.height / 2;

                            if (tooltipCenter > boundingCenter) {
                                top += boundingCenter - tooltipCenter;
                            } else {
                                top -= tooltipCenter - boundingCenter;
                            }
                        }
                    }

                    if (windowDimensions.height != null && top - screenPadding < 0) {
                        top = screenPadding;
                    }
                    if (windowDimensions.height != null && top + tooltipBox.height + screenPadding > windowDimensions.height) {
                        top = windowDimensions.height - tooltipBox.height - screenPadding;
                    }

                    if (screenPos.top !== top || screenPos.left !== left) {
                        setScreenPos({
                            top: top,
                            left: left
                        });
                    }
                }
            }
        }, 25);

        return () => {
            clearInterval(interval);
        }
    }, [element.current, show]);

    return (
        <div
            className={'tooltip-holder pos-' + position + ' ' + (show ? "show" : "hide") + " " + classes}
            style={{top: screenPos.top, left: screenPos.left}} ref={tooltip}>
            {arrow && (<div className={'tooltip-arrow'}></div>)}
            <div className={"tooltip"}>
                {children}
            </div>
        </div>
    );
};

export default Tooltip;
