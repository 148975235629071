import React, {FunctionComponent, useEffect, useRef} from 'react';
import "./NavbarDashboard.scss";
import logo from "./../../assets/icons/mcam-logo.svg";
import iconCamera from "./../../assets/icons/material/camera_indoor.svg";
import iconHistory from "./../../assets/icons/material/history.svg";
import iconSettings from "./../../assets/icons/material/settings.svg";
import {NavLink, useLocation} from "react-router-dom";
import SelfAvatar from "../data/SelfAvatar";

const NavbarDashboard: FunctionComponent = () => {
    const links = useRef<HTMLDivElement>(null);
    const border = useRef<HTMLDivElement>(null);

    const location = useLocation();

    useEffect(() => {
        if (!links.current || !border.current) {
            return;
        }

        let active = links.current.querySelector('.active');
        if (active) {
            let top = active.getBoundingClientRect().top;
            border.current.style.top = (top) + "px";
        }
    }, [location])

    return (
        <nav className={'dashboard-nav'}>
            <NavLink to={'/'}><img src={logo} alt={'MCam'} className={'logo'}/></NavLink>

            <div className={'border'} ref={border}/>
            <div className={'links'} ref={links}>
                <NavLink to={'/dashboard/'}>
                    <img src={iconCamera} alt={'CAM'}/>
                </NavLink>
                <NavLink to={'/dashboard/history/'}>
                    <img src={iconHistory} alt={'HIS'}/>
                </NavLink>
                <NavLink to={'/dashboard/settings/'}>
                    <img src={iconSettings} alt={'SET'}/>
                </NavLink>
            </div>

            <SelfAvatar side={"left"}/>
        </nav>
    );
};

export default NavbarDashboard;
