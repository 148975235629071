import React, {FunctionComponent} from 'react';
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import "./DSLiveCameraBar.scss";
import offlineIcon from "../../../../assets/icons/material/offline.svg";
import Spinner from "../../../util/Spinner";
import {CameraInfoOnline} from "../../../../data/camera";
import DSLiveLastPicture from "./DSLiveLastPicture";
import TemperatureReadingsChart from "./temp/TemperatureReadingsChart";
import UserAvatar from "../../../data/UserAvatar";
import refreshIcon from "../../../../assets/icons/material/refresh.svg";
import {useDashboard} from "../../../../contexts/DashboardContext";
import {Messages} from "../../../../data/messages";

const DSLiveCameraBar: FunctionComponent = () => {
    const {selectedCamera, cameraInfo} = useDashboardFunc();
    const {send} = useDashboard();

    if (!selectedCamera) {
        return null;
    }

    return (
        <div className={'camera-bar-wrapper ' + (!cameraInfo ? "loading" : "")}>
            <div className={"camera-bar-accessors"}>
                {!cameraInfo ? <Spinner/> : cameraInfo.accessors.map((accessor) => (
                    <UserAvatar user={accessor.user} key={accessor.id} type={"small"}/>
                ))}
            </div>
            <div
                className={'camera-bar ' + (!selectedCamera.online ? "offline" : "") + " " + (selectedCamera.online && !cameraInfo ? "loading" : "")}>
                {!selectedCamera.online ? (
                    <div className={'offline-box'}>
                        <img src={offlineIcon} className={"offline-img"} alt={"offline"}/>
                        <h3>Informacje niedostępne</h3>
                    </div>
                ) : (!cameraInfo ? (<Spinner type={"primary"}/>) : (
                    <>
                        <section>
                            <h3>Ostatnie zdjęcia</h3>
                            <div className={'last-pictures'}>
                                {(cameraInfo instanceof CameraInfoOnline && cameraInfo.lastPictures) && cameraInfo.lastPictures.map((pic) => (
                                    <DSLiveLastPicture name={pic} key={selectedCamera.id + "_" + pic}/>
                                ))}
                            </div>
                        </section>
                        <section>
                            <div className={'inline'}>
                                <h3>Wykres temperatur</h3>
                                <img src={refreshIcon} alt={'refresh'} className={'refresh-img'} onClick={() => {
                                    send(Messages.REQUEST_TEMP_READINGS, {
                                        camera_id: selectedCamera.id
                                    })
                                }}/>
                            </div>
                            <TemperatureReadingsChart/>
                        </section>
                    </>
                ))}
            </div>
        </div>
    );
};

export default DSLiveCameraBar;
