import React, {FunctionComponent} from 'react';
import "./DSLiveInfo.scss";
import {useDashboard} from "../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import Spinner from "../../../util/Spinner";
import DSLiveInfoOffline from "./infos/DSLiveInfoOffline";
import indentIcon from "../../../../assets/icons/material/arrow_right.svg";
import DSLiveInfoOnlineNews from "./infos/DSLiveInfoOnlineNews";
import DSLiveInfoOnlineActions from "./infos/DSLiveInfoOnlineActions";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

const DSLiveInfo: FunctionComponent = () => {
    const {subscribe, unsubscribe, send, isConnected} = useDashboard();
    const {selectedCamera, cameraInfo, refreshCameraInfo} = useDashboardFunc();

    if (!selectedCamera) {
        return null
    }

    if (!cameraInfo) {
        return <div className={'camera-info-loading'}>
            <Spinner type={'primary'}/>
        </div>;
    }

    const isCameraOffline = cameraInfo.status === "offline";
    if (selectedCamera.online !== !isCameraOffline) {
        refreshCameraInfo();
        return <div className={'camera-info-loading'}>
            <Spinner type={'primary'}/>
        </div>;
    }

    return (
        <div className={'camera-info'}>
            <OverlayScrollbarsComponent
                options={{overflowBehavior: {x: "hidden", y: "scroll"}, scrollbars: {autoHide: "scroll"}}}>
                <div className={'camera-info-section camera-info-info'}>
                    <div className={'camera-info-section-heading'}>
                        <img src={indentIcon}/>
                        <h4>Wiadomości</h4>
                    </div>
                    <div className={'camera-info-content'}>
                        {isCameraOffline ? (<DSLiveInfoOffline/>) : (<DSLiveInfoOnlineNews/>)}
                    </div>
                </div>
                {!isCameraOffline && (
                    <div className={'camera-info-section camera-info-actions'}>
                        <div className={'camera-info-section-heading'}>
                            <img src={indentIcon}/>
                            <h4>Akcje</h4>
                        </div>
                        <div className={'camera-info-content'}>
                            <DSLiveInfoOnlineActions/>
                        </div>
                    </div>
                )}
            </OverlayScrollbarsComponent>
        </div>
    );
};

export default DSLiveInfo;
