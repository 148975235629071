import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import "./DSLiveCameraView.scss";
import {useDashboard} from "../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import {Messages} from "../../../../data/messages";
import Spinner from "../../../util/Spinner";

import playRoundIcon from "../../../../assets/icons/material/play_round.svg";
import pauseRoundIcon from "../../../../assets/icons/material/pause_round.svg";

import playIcon from "../../../../assets/icons/material/play.svg";
import pauseIcon from "../../../../assets/icons/material/pause.svg";

import fullscreenIcon from "../../../../assets/icons/material/fullscreen.svg";

import offlineIcon from "../../../../assets/icons/material/offline.svg";
import {CameraInfoOnline} from "../../../../data/camera";
import Modal from "../../../data/Modal";
import DSLiveModal from "./modal/DSLiveModal";


const DSLiveCameraView: FunctionComponent = () => {
    const {subscribe, unsubscribe, send, isConnected} = useDashboard();
    const {selectedCamera, cameraInfo, streaming, setStreaming} = useDashboardFunc();

    const [toggleStreamBlocked, setToggleStreamBlocked] = useState(false);
    const [actionButtonState, setActionButtonState] = useState<"play" | "pause">("pause");
    const [fullscreen, setFullscreen] = useState(false);

    const actionBtnRef = useRef<HTMLImageElement>(null);
    const liveFrameRef = useRef<HTMLImageElement>(null);
    const spinnerRef = useRef<HTMLDivElement>(null);
    const fpsRef = useRef<HTMLDivElement>(null);
    const fpsNumRef = useRef<number | null>(null);
    const fullscreenRef = useRef<boolean>(false);

    const streamingRef = useRef(false);

    useEffect(() => {
        setStreaming(null);
    }, [])

    useEffect(() => {
        if (selectedCamera == null) {
            return;
        }

        if (liveFrameRef.current && streaming === null) {
            liveFrameRef.current.src = selectedCamera?.getLocalScreenshot();
        }

        let currentFps = 0;
        let first = true;

        const sub = subscribe(Messages.RESPOND_STREAM, (data) => {
            if (liveFrameRef.current) {
                let bytes = new Uint8Array(data);
                let binary = '';
                let len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i])
                }
                currentFps++;
                toggleLoading(false);

                const image = "data:image/jpg;base64," + window.btoa(binary);

                if (first) {
                    first = false;
                    selectedCamera.setLocalScreenshot(image);
                }

                if (fullscreenRef.current) {
                    return;
                }
                liveFrameRef.current.src = image;
            }
        });

        const fpsInterval = setInterval(() => {
            if (fpsRef.current) {
                fpsRef.current.innerHTML = currentFps + "fps";
                fpsNumRef.current = currentFps;
            }
            if (currentFps === 0 && streamingRef.current) {
                toggleLoading(true)
            }
            currentFps = 0;
        }, 1000);

        return () => {
            send(Messages.REQUEST_STREAM_END, {id: selectedCamera.id});
            unsubscribe(sub);
            clearInterval(fpsInterval);
        }
    }, []);

    useEffect(() => {
        if (selectedCamera == null) {
            return;
        }

        let interval: NodeJS.Timer | null = null;

        if (streaming) {
            send(Messages.REQUEST_STREAM_START, {id: selectedCamera.id});
            interval = setInterval(() => {
                send(Messages.REQUEST_STREAM_START, {id: selectedCamera.id});
            }, 7000)
        } else {
            if (streaming !== null) {
                send(Messages.REQUEST_STREAM_END, {id: selectedCamera.id});
            }

            toggleLoading(false);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [streaming]);

    useEffect(() => {
        if (selectedCamera == null) {
            return;
        }

        if (actionBtnRef.current) {
            if (isConnected) {
                actionBtnRef.current.classList.add("show");
                actionBtnRef.current.classList.remove("hide");
            } else {
                toggleLoading(true);
                actionBtnRef.current.classList.remove("show");
                actionBtnRef.current.classList.add("hide");
            }
        }
    }, [isConnected])

    if (selectedCamera == null) {
        return null;
    }

    const toggleStream = () => {
        if (!toggleStreamBlocked && actionBtnRef.current && fpsRef.current && selectedCamera.online) {
            setToggleStreamBlocked(true);

            if (streaming === null) {
                // didn't stream yet
                actionBtnRef.current.classList.remove("show");
                actionBtnRef.current.classList.add("hide");
                fpsRef.current.classList.add("show");
                setStreamingVal(true);
                toggleLoading(true);
                setTimeout(() => {
                    setToggleStreamBlocked(false);
                    setActionButtonState("pause");
                }, 200)
            } else if (streaming) {
                actionBtnRef.current.classList.remove("hide");
                actionBtnRef.current.classList.add("show");
                fpsRef.current.classList.remove("show");
                setStreamingVal(false);
                toggleLoading(false);
                setTimeout(() => {
                    if (actionBtnRef.current) {
                        actionBtnRef.current.classList.remove("show");
                        actionBtnRef.current.classList.add("hide");
                        setTimeout(() => {
                            setToggleStreamBlocked(false);
                            setActionButtonState("play");
                        }, 200)
                    }
                }, 400)
            } else {
                actionBtnRef.current.classList.remove("hide");
                actionBtnRef.current.classList.add("show");
                fpsRef.current.classList.add("show");
                setStreamingVal(true);
                toggleLoading(true);
                setTimeout(() => {
                    if (actionBtnRef.current) {
                        actionBtnRef.current.classList.remove("show");
                        actionBtnRef.current.classList.add("hide");
                        setTimeout(() => {
                            setToggleStreamBlocked(false);
                            setActionButtonState("pause");
                        }, 200)
                    }
                }, 400)
            }
        }
    }

    const toggleLoading = (load: boolean) => {
        if (spinnerRef.current) {
            if (load) {
                if (!spinnerRef.current.classList.contains("show")) {
                    spinnerRef.current.classList.remove("hide")
                    spinnerRef.current.classList.add("show")
                }
            } else {
                if (!spinnerRef.current.classList.contains("hide")) {
                    spinnerRef.current.classList.add("hide")
                    spinnerRef.current.classList.remove("show")
                }
            }
        }
    }

    const setStreamingVal = (val: boolean) => {
        streamingRef.current = val;
        setStreaming(val);
    }

    if (!selectedCamera.online && streaming) {
        setStreamingVal(false);
    }

    const toggleFullscreen = () => {
        if (!selectedCamera.online && !fullscreenRef.current) {
            return
        }

        fullscreenRef.current = !fullscreenRef.current;
        setFullscreen(fullscreenRef.current);
        console.log(fullscreenRef.current)
    }

    if (liveFrameRef.current && (liveFrameRef.current.src == "" || !liveFrameRef.current.src)) {
        liveFrameRef.current.src = selectedCamera.getLocalScreenshot();
    }

    return (
        <div className={'camera-view-live'}>
            <div className={'live-frame ' + (selectedCamera.online ? "" : "offline")} onClick={() => toggleStream()}>
                <img ref={liveFrameRef} className={'live-frame-img'}/>
                <div className={'spinner-holder hide'} ref={spinnerRef}><Spinner type={"background"}></Spinner></div>
                {selectedCamera.online ? (
                    <img ref={actionBtnRef} className={'action-button'} alt={'Play'}
                         src={actionButtonState !== "play" ? playRoundIcon : pauseRoundIcon}/>
                ) : (
                    <div className={'offline-info'}>
                        <img src={offlineIcon} alt={''}/>
                        <span>offline</span>
                    </div>
                )}
            </div>
            <div className={'controls'}>
                <img className={'state-button ' + (selectedCamera.online ? "" : "offline")} alt={'Play'}
                     src={!streaming ? playIcon : pauseIcon}
                     onClick={() => toggleStream()}/>
                <img className={'state-button fullscreen ' + (selectedCamera.online ? "" : "offline")}
                     alt={'Fullscreen'}
                     src={fullscreenIcon}
                     onClick={() => toggleFullscreen()}/>

                <div className={'controls-right'}>
                    <div className={'fps-counter'} ref={fpsRef}>

                    </div>
                    {cameraInfo && (cameraInfo instanceof CameraInfoOnline) && cameraInfo.format && (
                        <div className={'format'}>
                            <p>{cameraInfo.format}</p>
                        </div>
                    )}
                </div>
            </div>
            <Modal show={fullscreen} classes={"live-modal"}>
                <DSLiveModal open={fullscreen} online={selectedCamera.online} actionButtonState={actionButtonState}
                             toggleStream={() => toggleStream()} lastFrame={liveFrameRef.current?.src || ""}
                             onClose={() => toggleFullscreen()}
                             getFps={() => fpsNumRef.current}/>
            </Modal>
        </div>
    );
};

export default DSLiveCameraView;
