import React, {FunctionComponent, useRef, useState} from 'react';
import "./Avatar.scss";
import noneAvatar from './../../assets/icons/material/avatar.svg';
import adminIcon from './../../assets/icons/material/admin.svg';
import settingsIcon from './../../assets/icons/material/settings.svg';
import logoutIcon from './../../assets/icons/material/logout.svg';
import {useAuth} from "../../contexts/AuthContext";
import Tooltip from "./Tooltip";
import SelfAvatarEntry from "./SelfAvatarEntry";
import {useNavigate} from "react-router-dom";


const SelfAvatar: FunctionComponent<{ side?: "left" | "right" }> = ({side = "right"}) => {
    const navigate = useNavigate();
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const {getFromCookies, logout} = useAuth();

    const avatarRef = useRef<HTMLDivElement>(null);
    const user = getFromCookies();

    if (!user) {
        return null;
    }

    return (
        <div className={'user-avatar-wrapper'} tabIndex={0} onFocus={() => setHasFocus(true)}
             onBlur={() => setHasFocus(false)}>
            <div className={'user-avatar'} ref={avatarRef}>
                <div className={'outline'}
                     style={{borderColor: '#' + user.color, boxShadow: '0 4px 8px #' + user.color + "78"}}>
                    <img src={noneAvatar} alt={'You'}/>
                </div>
            </div>
            <Tooltip element={avatarRef} position={side === "left" ? "right" : "left"}
                     show={hasFocus} centered={true} classes={'user-tooltip'} arrow={false}>
                <div className={'user-tooltip-avatar'}>
                    <div className={'user-tooltip-avatar-bg'} style={{backgroundColor: '#' + user.color}}/>
                    <div className={'user-tooltip-avatar-image'}>
                        <img src={noneAvatar} alt={'You'}/>
                    </div>
                </div>
                <h3 className={'user-tooltip-nick'}>
                    {user.nick}
                </h3>
                <div className={'user-tooltip-line'}></div>
                <div className={'user-tooltip-menu'}>
                    <SelfAvatarEntry icon={settingsIcon} name={"Ustawienia"} onClick={() => {
                        navigate("/account");
                        setHasFocus(false);
                    }}/>
                    {user.admin === true ? (
                        <SelfAvatarEntry icon={adminIcon} name={"Zarządzanie"} onClick={() => {
                            navigate("/admin");
                            setHasFocus(false);
                        }}/>
                    ) : null}
                    <SelfAvatarEntry icon={logoutIcon} name={"Wyloguj się"} onClick={() => {
                        logout(false);
                        setHasFocus(false);
                    }}/>
                </div>
            </Tooltip>
        </div>
    );
};

export default SelfAvatar;
