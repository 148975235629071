import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import "./DSHistoryView.scss";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import Spinner from "../../../util/Spinner";
import DSHistoryPicture from './DSHistoryPicture';
import {useDashboard} from "../../../../contexts/DashboardContext";
import {Messages} from "../../../../data/messages";
import Modal from "../../../data/Modal";
import {picNameToDate} from "../../../../util/utils";
import dayjs from "dayjs";
import linkIcon from "../../../../assets/icons/material/link.svg";
import closeIcon from "../../../../assets/icons/material/close.svg";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

type Images =
    { date: { year: number, month: number, day: number }, images: { name: string, image?: string }[] }[]
    | null;

const DSHistoryView: FunctionComponent = () => {
    const {selectedCamera, cameraHistory} = useDashboardFunc();
    const {subscribe, unsubscribe, send} = useDashboard();
    const [fullscreenPic, setFullscreenPic] = useState<{ name: string, image: string } | null>(null);
    const [images, setImages] = useState<Images>(null);
    const imagesRef = useRef<Images>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<{ w: number, h: number } | null>(null);
    const windowSize = useWindowDimensions();

    useEffect(() => {
        if (!containerRef.current) {
            setSize(null);
            return
        }

        setSize(null);
        setTimeout(() => {
            if (!containerRef.current) {
                return
            }

            const rect = containerRef.current.getBoundingClientRect();
            setSize({w: rect.width, h: rect.height});
        }, 10)
    }, [windowSize])

    useEffect(() => {
        if (!selectedCamera) {
            return;
        }

        if (!cameraHistory) {
            send(Messages.REQUEST_PICTURE_LIST, JSON.stringify({id: selectedCamera.id}))
        }
    }, [])

    useEffect(() => {
        if (!cameraHistory) {
            setImages(null);
            imagesRef.current = null;
            return
        }

        if (!imagesRef.current) {
            imagesRef.current = [];
        }

        for (let image of cameraHistory.images) {
            const date = picNameToDate(image.name);
            if (!date) {
                continue;
            }

            let found = false;
            for (let datedImages of imagesRef.current) {
                let includes = false;
                for (let img of datedImages.images) {
                    if (img.name === image.name) {
                        includes = true;
                        break;
                    }
                }

                if (includes) {
                    found = true;
                    break
                }
                if (datedImages.date.year === date.getFullYear() && datedImages.date.month === date.getMonth() && datedImages.date.day === date.getDate()) {
                    datedImages.images.push(image);
                    found = true;
                    break
                }
            }

            if (!found) {
                imagesRef.current.push({
                    date: {year: date.getFullYear(), month: date.getMonth(), day: date.getDate()},
                    images: [image]
                });
            }
        }

        setImages(imagesRef.current);
    }, [cameraHistory])

    if (!selectedCamera) {
        return null;
    }

    const type = "big";

    return (
        <div className={'camera-history-view ' + (!cameraHistory ? "loading" : "")} ref={containerRef}>
            {!images || !cameraHistory ? (<Spinner type={"primary"}/>) : (
                <OverlayScrollbarsComponent options={{overflowBehavior: {x: "hidden", y: "scroll"}}}
                                            style={{maxWidth: size ? size.w + "px" : 0}}>
                    {images.map((datedImages, index) => {
                        const date = new Date();
                        date.setFullYear(datedImages.date.year);
                        date.setMonth(datedImages.date.month);
                        date.setDate(datedImages.date.day);


                        return (
                            <section key={JSON.stringify(datedImages.date)}>
                                <div className={'section-title'}>
                                    <div className={'line'}/>
                                    <h3>{dayjs(date).calendar(null, {
                                        sameDay: '[Dzisiaj]', // The same day ( Today at 2:30 AM )
                                        nextDay: '[Jutro]', // The next day ( Tomorrow at 2:30 AM )
                                        nextWeek: 'dddd', // The next week ( Sunday at 2:30 AM )
                                        lastDay: '[Wczoraj]', // The day before ( Yesterday at 2:30 AM )
                                        lastWeek: '[Ostatni] dddd', // Last week ( Last Monday at 2:30 AM )
                                        sameElse: 'DD-MM-YYYY' // Everything else ( 7/10/2011 )
                                    })}</h3>
                                </div>
                                <div className={'images'}>
                                    {datedImages.images.map((image, index) => {
                                        return (
                                            <DSHistoryPicture cameraId={selectedCamera.id} picName={image.name}
                                                              index={index}
                                                              key={selectedCamera.id + "_" + image.name} type={type}
                                                              imageDataPre={image.image === undefined ? null : image.image}
                                                              onClick={(img) => {
                                                                  setFullscreenPic({name: image.name, image: img});
                                                              }}/>
                                        )
                                    })}
                                </div>
                            </section>
                        )
                    })}
                </OverlayScrollbarsComponent>
            )}

            <Modal show={!!fullscreenPic} classes={"modal-history-picture"}>
                <div className={'modal-history-picture-wrapper'}>
                    <div className={'modal-history-title-wrapper'}>
                        <h2 className={'modal-history-title'}>
                            {fullscreenPic ? dayjs(picNameToDate(fullscreenPic.name)).format("YYYY-MM-DD HH:mm:ss") : ""}
                        </h2>
                        <div className={'modal-history-buttons'}>
                            <img src={linkIcon} alt={'open in new tab'} className={'icon-btn'}/>
                            <img src={closeIcon} alt={'close'} className={'icon-btn'}
                                 onClick={() => setFullscreenPic(null)}/>
                        </div>
                    </div>
                    <img src={fullscreenPic ? fullscreenPic.image : ""} className={'modal-picture-fullscreen'}/>
                </div>

            </Modal>
        </div>
    );
};

export default DSHistoryView;
