import React, {FunctionComponent, useState} from 'react';
import alertIcon from './../../../../../assets/icons/material/alert.svg';
import closeIcon from './../../../../../assets/icons/material/close.svg';
import passwordIcon from './../../../../../assets/icons/material/password.svg';
import cameraIcon from './../../../../../assets/icons/material/camera.svg';
import "./DSSettingsAdvancedModal.scss";
import Button from "../../../../forms/Button";
import FormProvider, {ValueHandlerProps} from "../../../../../contexts/FormContext";
import InputField, {InputTypes} from "../../../../forms/InputField";
import {Camera} from "../../../../../data/camera";

interface SaveModalProps {
    onClose: () => void;
    onAction: (password: string) => void;
    title: string;
    description: string;
    icon: string;
    camera: Camera;
}

type Props = SaveModalProps;
const DSSettingsAdvancedModal: FunctionComponent<Props> = ({onClose, onAction, title, description, icon, camera}) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [passwordSubmit, setpasswordSubmit] = useState<() => boolean>(() => {
        return () => false
    });
    const [passwordGetter, setpasswordGetter] = useState<(() => ValueHandlerProps | null)>(() => {
        return () => null
    });
    const [passwordInvalidSetter, setpasswordInvalidSetter] = useState<(((name: string, invalidValue: string, error: string) => void) | null)>(() => {
        return null
    });

    const submit = () => {
        if (!passwordSubmit || !passwordSubmit()) {
            return
        }

        const getter = passwordGetter();
        if (!getter) {
            return;
        }

        const password = getter['password'].value;

        if (!password) {
            return;
        }

        setLoading(true);
        (async () => {
            setErrorMessage(null);

        })();
    }

    return (<>
        <div className={'modal-advanced-title'}>
            <img src={icon} alt={'advanced'} className={'modal-title-icon'}/>
            <h2 className={'modal-title'}>{title}<span className={'accent-dot'}>.</span></h2>
            <img src={closeIcon} alt={'close'} className={'modal-close-icon'} onClick={() => onClose()}/>
        </div>
        <div className={'modal-advanced-description'}>
            {description}
        </div>
        <div className={'modal-advanced-camera-info'}>
            <p className={'action-info'}>Podejmujesz akcję dla kamery:</p>
            <div className={'camera-info'}>
                <div className={'inline'}>
                    <img src={cameraIcon} alt={'camera'} className={'camera-icon'}/>
                    <h3>{camera.name}</h3>
                </div>
                <span className={'sid'}>#{camera.sid}</span>
            </div>
        </div>
        <div className={'modal-advanced-input-wrapper'}>
            <p className={'rewrite-info'}>Wpisz swoje hasło aby kontynuować</p>
            <FormProvider submitHandler={(h) => setpasswordSubmit(() => h)}
                          valueHandler={(h) => setpasswordGetter(() => h)}
                          invalidSetHandler={(h) => setpasswordInvalidSetter(() => h)}>
                <InputField name={"password"} title={"Hasło"} icon={passwordIcon} type={InputTypes.password}
                            autofill={false}/>
            </FormProvider>
            <span className={'alert-info'}>Tej akcji nie można cofnąć.</span>
        </div>
        <div className={'buttons'}>
            <Button type={"filled-error"} onClick={() => {
                submit();
            }}>
                <img src={alertIcon} alt={'alert'}/>
                <span className={'text'}>
                    Wykonaj
                </span>
            </Button>
            <Button type={"outlined"} onClick={() => {
            }}>
                <span className={'text'}>
                    Anuluj
                </span>
            </Button>
        </div>

    </>);
};

export default DSSettingsAdvancedModal;
