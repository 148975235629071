import React, {FunctionComponent, useRef, useState} from 'react';
import {AccountLogin} from "../../pages/main/Account";
import loginIcon from "../../assets/icons/material/login.svg";
import dayjs from "dayjs";
import Tooltip from "../data/Tooltip";

interface AccountLoginProps {
    login: AccountLogin;
    first: boolean
}

type Props = AccountLoginProps;

const AccountLoginComponent: FunctionComponent<Props> = ({login, first}) => {
    const [hover, setHover] = useState(false);
    const dateRef = useRef<HTMLDivElement>(null);

    return (
        <div className={'account-login ' + (first ? "first" : "")}>
            <img src={loginIcon} alt={'login'} className={'login-icon'}/>
            <div className={'login-date'} ref={dateRef} onMouseEnter={() => setHover(true)}
                 onMouseLeave={() => setHover(false)}>
                {new Date(login.date).getTime() > new Date().getTime() ? "teraz" : dayjs(login.date).fromNow()}
            </div>
            <Tooltip element={dateRef} position={"right"} show={hover}>
                {dayjs(login.date).format("YYYY-MM-DD HH:mm:ss")}
            </Tooltip>
            <div className={'login-ip'}>
                {login.ip}
            </div>
        </div>
    );
};

export default AccountLoginComponent;
