import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import closeIcon from './../../../../../assets/icons/material/close.svg';
import cameraIcon from './../../../../../assets/icons/material/camera.svg';
import updateIcon from './../../../../../assets/icons/material/update.svg';
import settingsIcon from './../../../../../assets/icons/material/settings.svg';
import Button from "../../../../forms/Button";
import Spinner from "../../../../util/Spinner";
import "./DSSettingsUpdateModal.scss";
import {useDashboard} from "../../../../../contexts/DashboardContext";
import {Messages} from "../../../../../data/messages";
import {useDashboardFunc} from "../../../../../contexts/DashboardFuncContext";

interface UpdateModalProps {
    onClose: (updated: boolean) => void;
}

type Props = UpdateModalProps;

enum State {
    START,
    UPDATING,
    FINISH,
    ERROR
}

const millisToMinutesAndSeconds = (millis: number) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = parseInt(((millis % 60000) / 1000).toFixed(0));

    let minLast = parseInt(minutes.toString().substring(minutes.toString().length - 1));
    let minSuffix = '';
    if (minutes === 1) {
        minSuffix = "ę";
    } else if (minLast > 1 && minLast < 5) {
        minSuffix = "y";
    }

    let secLast = parseInt(seconds.toString().substring(seconds.toString().length - 1));
    let secSuffix = '';
    if (seconds === 1) {
        secSuffix = "ę";
    } else if (secLast > 1 && secLast < 5) {
        secSuffix = "y";
    }

    if (minutes > 0) {
        return minutes + " minut" + minSuffix + " i " + seconds + " sekund" + secSuffix;
    }

    return seconds + " sekund" + secSuffix;
}

const DSSettingsUpdateModal: FunctionComponent<Props> = ({onClose}) => {
    const {subscribe, unsubscribe, send} = useDashboard();
    const {selectedCamera, cameraSettings} = useDashboardFunc();
    const cache = useRef({camera: selectedCamera, settings: cameraSettings});

    const [state, setState] = useState<State>(State.START);
    const stateRef = useRef<State>(State.START);

    const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<{ stage: string, error_code: number, text: string } | null>(null);
    const [updatePercentage, setUpdatePercentage] = useState<{ progress: number, total: number, percentage: number } | null>(null);
    const [finishing, setFinishing] = useState(false);
    const [updateFinishTime, setUpdateFinishTime] = useState<number | null>(null);

    useEffect(() => {
        if (selectedCamera) {
            cache.current.camera = selectedCamera;
        }
        if (cameraSettings) {
            cache.current.settings = cameraSettings;
        }
    }, [selectedCamera, cameraSettings]);

    useEffect(() => {
        if (!cache.current.settings || !cache.current.camera) {
            return;
        }

        const sub = subscribe(Messages.RESPOND_UPDATE_STATUS, (data) => {
            const json = JSON.parse(data);
            if (json) {
                const stage = json['stage'];
                const error = json['error'];
                if (!stage) {
                    return;
                }

                const setErr = (text: string) => {
                    setState_(State.ERROR);
                    setErrorMessage({stage: stage, error_code: error, text: text});
                }

                if (stage === "before_start") {
                    if (error === 0) {
                        setErr("Nieprawidłowe żądanie")
                    } else if (error === 1) {
                        setErr("Odmowa dostępu")
                    } else if (error === 3) {
                        setErr("Odmowa dostępu")
                    } else if (error === 4) {
                        setErr("Aktualizacja w trakcie")
                    }
                } else if (stage === "start") {
                    if (error === -4) {
                        setErr("Nie znaleziono plików aktualizacyjnych dla tej kamery")
                    } else if (error === -3) {
                        setErr("Ta kamera ma już najnowszą wersję oprogramowania")
                    } else if (error === -2) {
                        setErr("Nie można odczytać wielkości pliku aktualizacyjnego")
                    } else if (error === -1) {
                        setErr("Plik aktualizacyjny jest pusty")
                    } else if (error === 0) {
                        setErr("Brak odpowiedzi ze strony kamery")
                    } else if (error === 1) {
                        setErr("Nie można odczytać odpowiedzi kamery")
                    } else if (error === 2) {
                        setErr("Brak wolnego miejsca w pamięci kamery (pozostało " + json['free_space'] + "b)")
                    }
                } else if (stage === "read") {
                    if (error === 0) {
                        setErr("Nie można odczytać zawartości pliku aktualizacyjnego")
                    }
                } else if (stage === "update") {
                    if (error != null) {
                        if (error === 0) {
                            setErr("Brak odpowiedzi ze strony kamery")
                        } else if (error === 1) {
                            setErr("Nie można odczytać odpowiedzi kamery")
                        }
                    } else {
                        const progress = json['progress'];
                        const total = json['total'];

                        if (progress != null && total != null) {
                            const percentage = progress / total * 100;
                            setUpdatePercentage({progress: progress, total: total, percentage: percentage});
                        } else {
                            setUpdatePercentage(null);
                        }

                        setState_(State.UPDATING);
                    }
                } else if (stage === "finish") {
                    if (error != null) {
                        if (error === 0) {

                        } else if (error === 1) {
                            setErr("Brak odpowiedzi ze strony kamery")
                        } else if (error === 2) {
                            setErr("Nie można odczytać odpowiedzi kamery")
                        } else if (error === 3) {
                            setErr("Nieznany błąd aktualizacji")
                        }
                    } else {
                        if (json.hasOwnProperty('pending')) {
                            setFinishing(true);
                        } else {
                            const time = json['time'];
                            setUpdateFinishTime(time);
                            setState_(State.FINISH);
                        }
                    }
                }
            }
        });

        return () => {
            unsubscribe(sub);
        }
    }, [])

    if (!cache.current.camera || !cache.current.settings) {
        return null;
    }

    const startUpdate = () => {
        if (updateButtonLoading) {
            return
        }
        setUpdateButtonLoading(true);

        send(Messages.REQUEST_START_UPDATE, {
            camera_id: cache.current.camera?.id
        })
    }

    const setState_ = (newState: State) => {
        if (stateRef.current !== newState) {
            stateRef.current = newState;
            setState(newState);
        }
    }

    const close = (updated: boolean) => {
        onClose(updated);
    }

    return (<>
        <div className={'modal-update-title'}>
            {state === State.START ? (<>
                <img src={settingsIcon} alt={'update'} className={'modal-title-icon'}/>
                <h2 className={'modal-title'}>Aktualizuj kamerę<span className={'accent-dot'}>.</span></h2>
            </>) : null}
            {state !== State.UPDATING ? (<img src={closeIcon} alt={'close'} className={'modal-close-icon'}
                                              onClick={() => close(false)}/>) : null}
        </div>
        <div className={'modal-update-data state-' + state}>
            {state === State.START ? (
                <>
                    <div className={'camera-title-wrapper'}>
                        <img src={cameraIcon} alt={'camera'} className={'camera-icon'}/>
                        <h3 className={'camera-title'}>
                            {cache.current.camera.name}
                        </h3>
                        <p className={'camera-sid'}>#{cache.current.camera.sid}</p>
                    </div>
                    <div className={'camera-version-info'}>
                        <h5 className={'camera-version'}>Wersja
                            oprogramowania <span>{cache.current.settings.softwareVersion}</span></h5>
                        <h5 className={'camera-version-new'}>Aktualizuj do
                            wersji <span>{cache.current.camera.model.softwareVersion}</span></h5>
                    </div>

                    <div className={'update-info'}>
                        <Button onClick={() => {
                            startUpdate()
                        }}>
                            {updateButtonLoading ? <Spinner type={'background'}/> : (<>Aktualizuj</>)}
                        </Button>
                        <p className={'info'}>Podczas aktualizacji kamera będzie niedostępna.<br/>Upewnij się, że będzie
                            miała stałe połączenie z WiFi i prąd.</p>
                    </div>
                </>
            ) : (state === State.UPDATING ? (
                <>
                    <img src={updateIcon} className={'update-icon'} alt={'update'}/>
                    <h3 className={'update-title'}>Aktualizacja w toku</h3>
                    {finishing ? (
                        <div className={'finish-loader'}>
                            <Spinner type={'primary'}/>
                        </div>
                    ) : (
                        <div className={'progress-bar'}>
                            <div className={'fill'}
                                 style={{maxWidth: updatePercentage ? updatePercentage.percentage + "%" : 0}}/>
                        </div>
                    )}
                    <div className={'progress-values'}>
                        {finishing ? (
                            <p className={'finishing'}>Instalowanie aktualizacji...</p>) : updatePercentage ? (
                            <>
                                <p className={'bytes'}>{(updatePercentage.progress / 1024).toFixed(0)}kB <span>/</span> {(updatePercentage.total / 1024).toFixed(0)}kB
                                </p>
                                <p className={'progress'}>{updatePercentage.percentage.toFixed(0)}%</p>
                            </>
                        ) : <Spinner type={'primary'}/>}
                    </div>
                </>
            ) : (state === State.FINISH ? (
                <>
                    <img src={updateIcon} className={'finish-icon'} alt={'finish'}/>
                    <h3 className={'finish-title'}>Aktualizacja zakończona</h3>
                    <p className={'finish-version'}>Zainstalowano najnowszą
                        wersję <span>{cache.current.camera.model.softwareVersion}</span></p>

                    <p className={'time-info'}>{updateFinishTime ? (<>Aktualizacja
                        zajęła {millisToMinutesAndSeconds(updateFinishTime)}</>) : null}</p>

                    <p className={'reboot-info'}>Kamera uruchomi się ponownie.</p>

                    <div className={'button-wrapper'}>
                        <Button onClick={() => close(true)}>
                            Zamknij
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <img src={updateIcon} className={'error-icon'} alt={'error'}/>
                    <h3 className={'error-title'}>Błąd aktualizacji</h3>
                    <div className={'error-data'}>
                        {errorMessage ? (
                            <>
                                <p className={'error-stage'}><span>etap</span>{errorMessage.stage}</p>
                                <p className={'error-code'}><span>kod błędu</span>{errorMessage.error_code}</p>
                                <p className={'error-text'}><span>powód</span>{errorMessage.text}</p>
                            </>
                        ) : (
                            <>
                                <p className={'error-text'}><span>powód</span>Nieznany błąd</p>
                            </>
                        )}
                    </div>

                    <p className={'again-info'}>Spróbuj ponownie później.</p>

                    <div className={'button-wrapper'}>
                        <Button onClick={() => close(false)}>
                            Zamknij
                        </Button>
                    </div>
                </>
            )))}
        </div>
    </>);
};

export default DSSettingsUpdateModal;
