import {useEffect} from 'react'
import {useAuth} from "../contexts/AuthContext";

function useProtected(admin: boolean = false) {
    const {getFromCookies, logout} = useAuth();
    const user = getFromCookies();

    useEffect(() => {
        if (!user?.token) {
            logout(true);
            return
        }
        if (admin && !user.admin) {
            //todo NO ADMIN error
        }
    }, [])
}

export default useProtected