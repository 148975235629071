import React, {FunctionComponent, useState} from 'react';
import addIcon from './../../../../assets/icons/material/add.svg';
import closeIcon from './../../../../assets/icons/material/close.svg';
import FormProvider, {ValueHandlerProps} from "../../../../contexts/FormContext";
import InputField, {InputTypes} from "../../../forms/InputField";
import sidIcon from './../../../../assets/icons/material/sid.svg';
import nameIcon from './../../../../assets/icons/material/name.svg';
import {AuthCookies, useAuth} from "../../../../contexts/AuthContext";
import axios, {AxiosError} from "axios";
import Button from "../../../forms/Button";
import Spinner from "../../../util/Spinner";
import "./DSCameraAddModal.scss";

interface CameraAddModalProps {
    onClose: (added: boolean) => void;
}

type Props = CameraAddModalProps;

const apiServer = process.env.REACT_APP_API_SERVER;
const sendAction = async (cookies: AuthCookies, sid: string, name: string): Promise<{ status: number, error?: number, id?: number }> => {
    try {
        const response = await axios.post(apiServer + "/camera/register", {
            nick: cookies.nick,
            token: cookies.token,
            sid: sid,
            name: name
        });

        if (response.status === 200) {
            return {status: 200, id: response.data['id']};
        } else {
            return {status: response.status, error: response.data['code']};
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e.response) {
                return {
                    status: e.response.status,
                    error: e.response.data ? e.response.data['code'] : null
                };
            }
        }
    }

    return {status: 404};
}

const DSCameraAddModal: FunctionComponent<Props> = ({onClose}) => {
    const {getFromCookies} = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [addCamSubmit, setAddCamSubmit] = useState<() => boolean>(() => {
        return () => false
    });
    const [addCamGetter, setAddCamGetter] = useState<(() => ValueHandlerProps | null)>(() => {
        return () => null
    });
    const [addCamInvalidSetter, setAddCamInvalidSetter] = useState<(((name: string, invalidValue: string, error: string) => void) | null)>(() => {
        return null
    });

    const cookies = getFromCookies();
    if (!cookies) {
        return null;
    }

    const submit = () => {
        if (!addCamSubmit || !addCamSubmit()) {
            return
        }

        const getter = addCamGetter();
        if (!getter) {
            return;
        }

        const sid = getter['sid'].value;
        const name = getter['name'].value;

        if (!sid || !name) {
            return;
        }

        setLoading(true);
        (async () => {
            setErrorMessage(null);
            const response = await sendAction(cookies, sid, name);
            if (response.status === 200) {
                onClose(true);
            } else {
                switch (response.status) {
                    case 400:
                        if (response.error != null && response.error !== 0 && addCamInvalidSetter) {
                            if (response.error === -1) {
                                addCamInvalidSetter("sid", sid, "Ten SID nie istnieje.")
                            } else if (response.error === -2) {
                                addCamInvalidSetter("sid", name, "Ten SID jest już zarejestrowany.");
                            } else if (response.error === -3) {
                                addCamInvalidSetter("name", sid, "Nazwa jest nieprawidłowa. Wymyśl nazwę kamery do 50 znaków.");
                            }

                            break
                        }

                        setErrorMessage("Nieprawidłowe żadanie. Spróbuj jeszcze raz.")
                        break

                    case 401:
                        setErrorMessage("Błąd autoryzacji. Spróbuj jeszcze raz.")
                        break
                    case 500:
                        setErrorMessage("Błąd w przetwarzaniu danych. Spróbuj jeszcze raz.")
                        break
                    default:
                        setErrorMessage("Błąd " + response.status + ". Spróbuj jeszcze raz.")
                        break
                }
            }
            setLoading(false);
        })();
    }

    return (<>
        <div className={'modal-add-camera-title'}>
            <img src={addIcon} alt={'add'} className={'modal-title-icon'}/>
            <h2 className={'modal-title'}>Dodaj kamerę<span className={'accent-dot'}>.</span></h2>
            <img src={closeIcon} alt={'add'} className={'modal-close-icon'} onClick={() => onClose(false)}/>
        </div>
        <div className={'modal-add-camera-form'}>
            <FormProvider submitHandler={(h) => setAddCamSubmit(() => h)} valueHandler={(h) => setAddCamGetter(() => h)}
                          invalidSetHandler={(h) => setAddCamInvalidSetter(() => h)}>
                <InputField key={'cam_sid'} name={'sid'} title={"SID"} icon={sidIcon}
                            type={InputTypes.sid}
                ></InputField>
                <InputField key={'cam_name'} name={'name'} title={"Nazwa własna"} icon={nameIcon}
                            type={InputTypes.nick}
                ></InputField>
                <div className={"buttons"}>
                    <Button type={"filled"} onClick={() => {
                        submit()
                    }}>
                        {loading ? (
                            <Spinner type={'background'}></Spinner>
                        ) : (<span className={'text'}>Zarejestruj kamerę</span>)}
                    </Button>
                </div>
            </FormProvider>
            <div className={"error-message"}>
                {errorMessage && (<span>{errorMessage}</span>)}
            </div>
        </div>
    </>);
};

export default DSCameraAddModal;
