import React, {FunctionComponent} from 'react';
import "./Spinner.scss";

interface SpinnerProps {
    type?: "primary" | "secondary" | "background"
}

type Props = SpinnerProps

const Spinner: FunctionComponent<Props> = ({type = "primary"}) => {
    return (
        <svg className={"spinner spinner-" + type} width="24px" height="24px" viewBox="0 0 66 66"
             xmlns="http://www.w3.org/2000/svg">
            <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33"
                    r="30"></circle>
        </svg>
    );
};

export default Spinner;
