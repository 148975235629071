import React, {FunctionComponent} from 'react';
import NavbarHome from "../../components/home/NavbarHome";
import {Route, Routes, useLocation} from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import "./StartPage.scss";
import Footer from "../../components/home/Footer";
import Account from "./Account";
import Error from "../errors/Error";

const StartPage: FunctionComponent = () => {
    const location = useLocation();
    return (
        <>
            <div className={"body-wrapper page-" + location.pathname.replace("/", "-")}>
                <NavbarHome></NavbarHome>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/login'} element={<Login type={"login"}/>}/>
                    <Route path={'/register'} element={<Login type={"register"}/>}/>
                    <Route path={'/account'} element={<Account/>}/>
                    <Route path={'*'} element={<Error code={404} title={"Nie znaleziono strony"}
                                                      description={"Upewnij się, czy w adresie nie ma literówek oraz czy ta strona napewno istnieje."}/>}/>
                </Routes>
            </div>
            {location.pathname === '/' && (<Footer></Footer>)}
        </>
    );
};

export default StartPage;
