import React, {FocusEvent, FunctionComponent, ReactNode} from 'react';

export const OnBlurComponent: FunctionComponent<{ onBlur: () => void, children: ReactNode }> = ({children, onBlur}) => {
    const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
        const currentTarget = e.currentTarget;

        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                onBlur();
            }
        }, 0);
    };

    return (
        <div tabIndex={1} onBlur={(e) => {
            handleBlur(e)
        }}>
            {children}
        </div>
    );
}