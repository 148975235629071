import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import closeIcon from './../../../../../assets/icons/material/close.svg';
import debugIcon from './../../../../../assets/icons/material/debug.svg';
import "./DSSettingsDebugModal.scss";
import {useDashboardFunc} from "../../../../../contexts/DashboardFuncContext";
import {useDashboard} from "../../../../../contexts/DashboardContext";
import {Messages} from "../../../../../data/messages";
import Spinner from "../../../../util/Spinner";
import MemoryBar from "../memory/MemoryBar";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";

interface DebugModalProps {
    show: boolean;
    onClose: () => void;
}

class DebugData {
    heapTotalSize: number;
    heapFreeSize: number;
    psramTotalSize: number;
    psramFreeSize: number;
    sdTotalSize: number;
    sdUsedSize: number;
    debugLogs: { m: number, l: string }[];
    errorLogs?: string;

    constructor(heapTotalSize: number, heapFreeSize: number, psramTotalSize: number, psramFreeSize: number, sdTotalSize: number, sdUsedSize: number, debugLogs: { m: number, l: string }[], errorLogs?: string) {
        this.heapTotalSize = heapTotalSize;
        this.heapFreeSize = heapFreeSize;
        this.psramTotalSize = psramTotalSize;
        this.psramFreeSize = psramFreeSize;
        this.sdTotalSize = sdTotalSize;
        this.sdUsedSize = sdUsedSize;
        this.debugLogs = debugLogs;
        this.errorLogs = errorLogs;
    }

    public static fromServer(json: any): DebugData | null {
        if (!json.hasOwnProperty("heap_total_size")) {
            return null
        }
        if (!json.hasOwnProperty("heap_free_size")) {
            return null
        }
        if (!json.hasOwnProperty("psram_total_size")) {
            return null
        }
        if (!json.hasOwnProperty("psram_free_size")) {
            return null
        }
        if (!json.hasOwnProperty("sd_total_size")) {
            return null
        }
        if (!json.hasOwnProperty("sd_used_size")) {
            return null
        }
        if (!json.hasOwnProperty("logs")) {
            return null
        }

        return new DebugData(
            json['heap_total_size'],
            json['heap_free_size'],
            json['psram_total_size'],
            json['psram_free_size'],
            json['sd_total_size'],
            json['sd_used_size'],
            json['logs'],
            "Error"
        )
    }
}

type Props = DebugModalProps;
const DSSettingsDebugModal: FunctionComponent<Props> = ({show, onClose}) => {
    const {subscribe, unsubscribe, send} = useDashboard();
    const {selectedCamera, cameraInfo, cameraSettings} = useDashboardFunc();
    const [debugData, setDebugData] = useState<DebugData | null>(null);
    const [timeToUpdate, setTimeToUpdate] = useState<number>(0);
    const timeToUpdateRef = useRef(0);
    const consoleRef = useRef<OverlayScrollbarsComponent>(null);


    useEffect(() => {
        if (!selectedCamera || !cameraInfo || !cameraSettings || !cameraSettings.admin || !show) {
            return;
        }
        console.log('sub')

        const sub = subscribe(Messages.RESPOND_DEBUG_INFO, (data) => {
            const json = JSON.parse(data);
            if (json) {
                if (json['camera'] === selectedCamera.id) {
                    console.log("DATA:");
                    console.log(json);
                    let scrollDown = false;
                    if (consoleRef.current) {
                        const instance = consoleRef.current.osInstance();
                        if (instance) {
                            if (instance.scroll().position.y === instance.scroll().max.y) {
                                scrollDown = true;
                            }
                        }
                    }
                    setDebugData(DebugData.fromServer(json['data']));
                    if (consoleRef.current) {
                        const instance = consoleRef.current.osInstance();
                        if (instance) {
                            if (scrollDown) {
                                instance.scroll({y: "100%"}, 1500);
                            }
                        }
                    }
                }
            }

            timeToUpdateRef.current = 5;
            setTimeToUpdate(timeToUpdateRef.current);
        });

        const interval = setInterval(() => {
            if (timeToUpdateRef.current != null && show) {
                if (timeToUpdateRef.current > 0) {
                    timeToUpdateRef.current--;
                    if (timeToUpdateRef.current === 0) {
                        send(Messages.REQUEST_DEBUG_INFO, {
                            camera_id: selectedCamera.id
                        })
                    }

                    setTimeToUpdate(timeToUpdateRef.current);
                }
            }
        }, 1000)

        send(Messages.REQUEST_DEBUG_INFO, {
            camera_id: selectedCamera.id
        })

        return (() => {
            console.log('close')
            unsubscribe(sub);
            clearInterval(interval);
        })
    }, [show])

    if (!selectedCamera || !cameraInfo || !cameraSettings || !cameraSettings.admin) {
        return null;
    }

    return (<>
        <div className={'modal-debug-title'}>
            <img src={debugIcon} alt={'save'} className={'modal-title-icon'}/>
            <h2 className={'modal-title'}>Informacje Debugowania<span className={'accent-dot'}>.</span></h2>
            <img src={closeIcon} alt={'close'} className={'modal-close-icon'} onClick={() => onClose()}/>
            <div className={'debug-update'}>
                {timeToUpdate}s
            </div>
        </div>
        {debugData == null ? (<div className={'modal-debug-loading'}><Spinner/></div>) : (
            <>
                <div className={'modal-debug-data'}>
                    <h3>Informacje</h3>
                    <div className={'data'}>
                        <section>
                            <p className={'title'}>Heap</p>
                            <div className={'value'}><MemoryBar totalBytes={debugData.heapTotalSize}
                                                                usedBytes={debugData.heapTotalSize - debugData.heapFreeSize}/>
                            </div>
                        </section>
                        <section>
                            <p className={'title'}>PSRAM</p>
                            <div className={'value'}><MemoryBar totalBytes={debugData.psramTotalSize}
                                                                usedBytes={debugData.psramTotalSize - debugData.psramFreeSize}/>
                            </div>
                        </section>
                        <section>
                            <p className={'title'}>SD</p>
                            <div className={'value'}><MemoryBar totalBytes={debugData.sdTotalSize}
                                                                usedBytes={debugData.sdUsedSize}/></div>
                        </section>
                    </div>
                </div>
                <div className={'modal-debug-logs'}>
                    <h3>Konsola</h3>
                    <div className={'console-wrapper'}>
                        <OverlayScrollbarsComponent options={{
                            overflowBehavior: {
                                x: "scroll",
                                y: "scroll"
                            }
                        }} ref={consoleRef}>
                            <div className={'console'}>
                                {debugData.debugLogs.map((log, index) => {
                                    let type = "unknown";
                                    switch (log.l.at(log.l.indexOf("(") + 1)) {
                                        case "D": {
                                            type = "debug";
                                            break
                                        }
                                        case "I": {
                                            type = "info";
                                            break
                                        }
                                        case "W": {
                                            type = "warn";
                                            break
                                        }
                                        case "E": {
                                            type = "error";
                                            break
                                        }
                                    }

                                    return (
                                        <div className={'log'} key={selectedCamera.id + "_" + log.m + "_" + index}>
                                        <span className={'millis'}>
                                            {log.m}
                                        </span>
                                            <span className={'text ' + type}>
                                            {log.l}
                                        </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </OverlayScrollbarsComponent>
                    </div>
                </div>
            </>
        )}

    </>);
};

export default DSSettingsDebugModal;
