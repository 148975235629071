import React, {FunctionComponent, ReactNode, useRef} from 'react';
import "./Modal.scss";

interface ModalProps {
    children: ReactNode;
    show: boolean;
    classes?: string;
}

type Props = ModalProps;


const Modal: FunctionComponent<Props> = ({children, show, classes}) => {
    const shownBefore = useRef(false);

    if (show) {
        shownBefore.current = true;
    }

    return (
        <div
            className={'modal ' + (shownBefore.current ? (show ? "show" : "hide") : "") + " " + (classes ? classes : "")}>
            <div className={'modal-inside ' + (show ? "show" : "hide")}>
                <div className={'modal-content'}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
