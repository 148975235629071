import React from 'react';
import './App.css';
import "./assets/styles/global.scss";
import StartPage from './pages/main/StartPage';
import {Route, Routes} from "react-router-dom";
import Dashboard from "./pages/panel/Dashboard";
import {AuthProvider} from "./contexts/AuthContext";
import DashboardContext from "./contexts/DashboardContext";
import DashboardFuncContext from "./contexts/DashboardFuncContext";
import {AddTrailingSlash} from "./util/trailing_slash";
import AlertProvider from "./contexts/AlertContext";

function App() {
    return (
        <AuthProvider>
            <AlertProvider>
                <AddTrailingSlash/>
                <Routes>
                    <Route path={'/*'} element={<StartPage/>}/>
                    <Route path={'/dashboard/*'} element={
                        <DashboardContext><DashboardFuncContext><Dashboard/></DashboardFuncContext></DashboardContext>}/>
                </Routes>
            </AlertProvider>
        </AuthProvider>
    );
}

export default App;
