import React, {FunctionComponent, ReactNode} from 'react';
import "./DSCameraContextView.scss";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import DSCameraViewTitle from "./DSCameraViewTitle";
import selectIcon from "../../../../assets/icons/material/select.svg";
import offlineIcon from "../../../../assets/icons/material/offline.svg";

interface DashboardCameraViewProps {
    children: ReactNode,
    onlineRequired?: boolean;
    titleData?: ReactNode
}

type Props = DashboardCameraViewProps;

const DSCameraContextView: FunctionComponent<Props> = ({children, onlineRequired = false, titleData}) => {
    const {selectedCamera} = useDashboardFunc();

    return (
        <div className={'camera-context-view'}>
            <DSCameraViewTitle>
                {titleData}
            </DSCameraViewTitle>
            <div
                className={'camera-context-view-box ' + (selectedCamera === null || (onlineRequired && !selectedCamera.online) ? "no-camera" : "")}>
                {selectedCamera === null ? (
                    <div className={'select-box'}>
                        <img src={selectIcon} className={'select-img'} alt={"select"}/>
                        <h3>Wybierz kamerę z listy</h3>
                    </div>
                ) : ((onlineRequired && !selectedCamera.online) ? (
                    <div className={'select-box'}>
                        <img src={offlineIcon} className={'select-img'} alt={"select"}/>
                        <h3>Kamera niedostępna</h3>
                    </div>
                ) : (children))}
            </div>
        </div>
    );
};

export default DSCameraContextView;
