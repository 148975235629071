import React, {FunctionComponent, useRef, useState} from 'react';
import './DashboardRouter.scss';
import refreshIcon from './../../../assets/icons/material/refresh.svg';
import addIcon from './../../../assets/icons/material/add.svg';
import DSCameraList from "./router/DSCameraList";
import DSCameraContextView from "./router/DSCameraContextView";
import Tooltip from "../../data/Tooltip";
import {Route, Routes, useLocation} from "react-router-dom";
import DSLiveCameraView from "./live/DSLiveCameraView";
import DSLiveInfo from "./live/DSLiveInfo";
import DSHistoryView from "./history/DSHistoryView";
import DSLiveCameraBar from './live/DSLiveCameraBar';
import DSSettingsView from "./settings/DSSettingsView";
import DSSettingsTitle from "./settings/DSSettingsTitle";
import Modal from "../../data/Modal";
import DSCameraAddModal from "./router/DSCameraAddModal";
import {useDashboardFunc} from "../../../contexts/DashboardFuncContext";
import DSLiveCameraTitle from "./live/DSLiveCameraTitle";
import DSHistoryTitle from "./history/DSHistoryTitle";
import {useCameraMenu} from "../../../contexts/CameraMenuContext";
import menuIcon from "../../../assets/icons/material/menu.svg";

const DashboardRouter: FunctionComponent = () => {
    const {refreshCameraList} = useDashboardFunc();
    const [refreshHover, setRefreshHover] = useState(false);
    const [addCameraHover, setAddCameraHover] = useState(false);
    const [addCameraModal, setAddCameraModal] = useState(false);
    const location = useLocation();
    const {shown, toggle} = useCameraMenu();

    const refreshIconRef = useRef<HTMLImageElement>(null);
    const addCameraIconRef = useRef<HTMLImageElement>(null);

    let title = "Podgląd";
    if (location.pathname.includes("/dashboard/history")) {
        title = "Historia"
    } else if (location.pathname.includes("/dashboard/settings")) {
        title = "Ustawienia"
    }

    return (
        <div className={'dashboard-router'}>
            <div className={'cameras ' + (shown == null ? "" : (shown ? "show" : "hide"))}>
                <div className={'title-bar'}>
                    <div className={'cameras-menu'}>
                        <img src={menuIcon} alt={'Kamery...'} onClick={() => {
                            toggle()
                        }}/>
                    </div>
                    <h1>{title}<span className={'accent-dot'}>.</span></h1>
                    <div className={'icon-holder'} onMouseEnter={() => setRefreshHover(true)}
                         onMouseLeave={() => setRefreshHover(false)}>
                        <img src={refreshIcon} ref={refreshIconRef} alt={'refresh'} className={'title-icon'}
                             onClick={() => {
                                 refreshCameraList()
                             }}/>
                        <Tooltip element={refreshIconRef} position={"right"} show={refreshHover}>
                            Odśwież
                        </Tooltip>
                    </div>
                    <div className={'icon-holder'} onMouseEnter={() => setAddCameraHover(true)}
                         onMouseLeave={() => setAddCameraHover(false)}>
                        <img src={addIcon} ref={addCameraIconRef} alt={'add camera'} className={'title-icon'}
                             onClick={() => setAddCameraModal(true)}/>
                        <Tooltip element={addCameraIconRef} position={"right"} show={addCameraHover}>
                            Dodaj kamerę
                        </Tooltip>
                    </div>
                </div>
                <DSCameraList onCameraSelect={(camera) => {
                }} onCameraAdd={() => {
                    setAddCameraModal(true)
                }}></DSCameraList>

                <Modal show={addCameraModal} classes={"modal-add-camera"}>
                    <DSCameraAddModal onClose={(added) => {
                        setAddCameraModal(false);
                        if (added) {
                            refreshCameraList();
                        }
                    }}/>
                </Modal>
            </div>
            <Routes>
                <Route path={'/'} element={
                    <div className={"camera-live-wrapper"}>
                        <DSCameraContextView titleData={<DSLiveCameraTitle/>}>
                            <>
                                <DSLiveCameraView/>
                                <DSLiveInfo/>
                            </>
                        </DSCameraContextView>
                        <DSLiveCameraBar/>
                    </div>
                }/>
                <Route path={'/history'} element={
                    <div className={"camera-live-wrapper"}>
                        <DSCameraContextView onlineRequired={true} titleData={<DSHistoryTitle/>}>
                            <>
                                <DSHistoryView/>
                            </>
                        </DSCameraContextView>
                    </div>
                }/>
                <Route path={'/settings'} element={
                    <div className={"camera-live-wrapper"}>
                        <DSCameraContextView onlineRequired={true} titleData={<DSSettingsTitle/>}>
                            <>
                                <DSSettingsView/>
                            </>
                        </DSCameraContextView>
                    </div>
                }/>
            </Routes>
        </div>
    );
};

export default DashboardRouter;
