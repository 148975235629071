import React, {FunctionComponent} from 'react';
import "./DSHistoryTitle.scss";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import RoundProgressBar from "../../../util/RoundProgressBar";

const DSLiveCameraTitle: FunctionComponent = () => {
    const {selectedCamera, cameraHistory} = useDashboardFunc();

    if (!selectedCamera || !cameraHistory) {
        return null;
    }

    const lastLengthNum = parseInt(cameraHistory.images.length.toFixed(0).charAt(cameraHistory.images.length.toFixed(0).length - 1));

    return (
        <div className={"camera-history-title"}>
            <div className={'progress-bars'}>
                <RoundProgressBar progress={((cameraHistory.sdUsedSize / cameraHistory.sdTotalSize) * 100)}>
                    SD<br/>
                    {(cameraHistory.sdUsedSize / cameraHistory.sdTotalSize * 100).toFixed(0)}%
                </RoundProgressBar>
                <RoundProgressBar progress={((cameraHistory.images.length / cameraHistory.maxImages) * 100)}>
                    ZDJ<br/>
                    {(cameraHistory.images.length / cameraHistory.maxImages * 100).toFixed(0)}%
                </RoundProgressBar>
            </div>
            <div className={'images-size'}>
                {cameraHistory.images.length} {lastLengthNum === 0 || lastLengthNum > 4 ? "zdjęć" : (lastLengthNum === 1 ? (cameraHistory.images.length === 1 ? "zdjęcie" : "zdjęć") : "zdjęcia")}
            </div>
        </div>
    );
};

export default DSLiveCameraTitle;
