import React, {FunctionComponent, useState} from 'react';
import Button from "../../../../forms/Button";
import Spinner from "../../../../util/Spinner";

interface AdvancedBtnProps {
    onClick: () => boolean;
    icon: string;
    load?: boolean;
}


type Props = AdvancedBtnProps;

const AdvancedButton: FunctionComponent<Props> = ({onClick, icon, load = false}) => {
    const [loading, setLoading] = useState(false);

    const doLoad = () => {
        if (!loading && load) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        }
    }

    return (<div className={'advanced-button-wrapper'}>
        <Button type={'filled-error'} onClick={() => {
            if (!loading && onClick()) {
                doLoad()
            }
        }}>
            {loading ? <Spinner type={'background'}/> : (
                <img className={'advanced-btn-icon'} src={icon}/>
            )}
        </Button>
    </div>);
};

export default AdvancedButton;
