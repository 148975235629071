import React, {FunctionComponent} from 'react';

interface SelfAvatarProps {
    icon: string;
    name: string;
    onClick: () => void;
}

type Props = SelfAvatarProps;

const SelfAvatarEntry: FunctionComponent<Props> = ({icon, name, onClick}) => {

    return (
        <div className={'user-tooltip-menu-entry'} onClick={() => onClick()}>
            <img src={icon} alt={'settings'} className={'menu-entry-icon'}/>
            <span className={'menu-entry-text'}>{name}</span>
        </div>
    );
};

export default SelfAvatarEntry;
