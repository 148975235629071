import React, {FunctionComponent} from 'react';
import "./Error.scss";
import errorIcon from "../../assets/icons/material/exclamation.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import Button from "../../components/forms/Button";
import {useNavigate} from "react-router-dom";

interface ErrorParams {
    code: number;
    title: string;
    description: string;
}

const Home: FunctionComponent<ErrorParams> = ({code, title, description}) => {
    useDocumentTitle("MCam | Błąd " + code);
    const navigate = useNavigate();

    return (
        <div className={'error-wrapper'}>
            <div className={'error-background'}>
                <div className={'error-background-stroke'}/>
            </div>
            <div className={'error-content'}>
                <div className={'error-title'}>
                    <img src={errorIcon}/>
                    <h1>Błąd #{code}</h1>
                </div>
                <div className={'error-data'}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                <div className={'error-btn'}>
                    <Button onClick={() => {
                        navigate("/")
                    }}>
                        Wróć do strony głównej
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Home;
