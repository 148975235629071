import React, {FunctionComponent, ReactNode} from 'react';
import "./RoundProgressBar.scss";

interface ProgressProps {
    progress: number; // 0 - 100
    children: ReactNode;
}

type Props = ProgressProps;

const RoundProgressBar: FunctionComponent<Props> = ({progress, children}) => {

    return (
        <div className={'round-progress-bar'} style={{"--progress": (progress * 3.6) + "deg"} as React.CSSProperties}>
            <div className={'round-progress-bar-inner'}>
                {children}
            </div>
        </div>
    );
};

export default RoundProgressBar;
