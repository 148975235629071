import React, {FunctionComponent} from 'react';
import mcamDark from './../../assets/icons/mcam_dark.svg';
import "./Home.scss";
import Button from "../../components/forms/Button";
import {useNavigate} from "react-router-dom";
import HomeFeature from "./HomeFeature";
import streamIcon from "./../../assets/icons/material/stream.svg";
import motionIcon from "./../../assets/icons/material/motion.svg";
import photoIcon from "./../../assets/icons/material/take_picture.svg";
import historyIcon from "./../../assets/icons/material/history.svg";
import temperatureIcon from "./../../assets/icons/material/temperature.svg";
import signalsIcon from "./../../assets/icons/material/light.svg";
import usersIcon from "./../../assets/icons/material/user.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {useAuth} from "../../contexts/AuthContext";

const Home: FunctionComponent = () => {
    const navigate = useNavigate();
    useDocumentTitle("MCam | Strona główna");
    const {getFromCookies} = useAuth();


    return (
        <>
            <div className={"home-background"}>
                <div className={"home-stroke"}></div>
            </div>
            <div className={"home-title-holder"}>
                <h1>A może by tak<span className={"accent-dot"}>...</span></h1>
                <h2>...rzucić wszystko i wyjechać w Bieszczady<span className={"accent-dot"}>?</span></h2>
            </div>
            <div className={"center-holder"}>
                <div className={'spacer-top'}/>
                <h1>Z <img src={mcamDark} alt={'MCam'}/> to nie problem<span className={"accent-dot"}>.</span></h1>
                <div className={'features'}>
                    <HomeFeature title={"Transmisja z kamery na żywo"} icon={streamIcon}/>
                    <HomeFeature title={"Wykrywanie ruchu"} icon={motionIcon}/>
                    <HomeFeature title={"Zapisywanie zdjęć na karcie"} icon={photoIcon}/>
                    <HomeFeature title={"Historia zapisanych zdjęć"} icon={historyIcon}/>
                    <HomeFeature title={"Sygnały wizualne"} icon={signalsIcon}/>
                    <HomeFeature title={"Aktualna temperatura"} icon={temperatureIcon}/>
                    <HomeFeature title={"Dodawanie użytkowników"} icon={usersIcon}/>
                </div>
                <div className={'spacer-bottom'}/>
            </div>
            <div className={"login-blob"}>
                <div className={'login-inner'}>
                    {!getFromCookies() ? (
                        <>
                            <h5>Na co czekasz?</h5>
                            <Button type={"filled"} onClick={() => {
                                navigate("login", {replace: true});
                            }}>
                                Zaloguj się
                            </Button>
                        </>
                    ) : (
                        <>
                            <h5>Zalogowano!</h5>
                            <Button type={"filled"} onClick={() => {
                                navigate("dashboard", {replace: true});
                            }}>
                                Przejdź do panelu
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <div className={'bottom-holder'}>
                <div className={'home-bottom-background'}></div>
            </div>
        </>
    );
};

export default Home;
