import React, {FunctionComponent, ReactNode, useRef} from 'react';

interface ButtonProps {
    children: ReactNode;
    icon?: string;
    type?: ButtonType;
    disabled?: boolean;
    onClick: () => void;
    element?: (ref: React.RefObject<HTMLButtonElement>) => void;
}

type ButtonType = "filled" | "filled-dark" | "filled-error" | "outlined" | "text";

type Props = ButtonProps;

const Button: FunctionComponent<Props> = ({children, icon, type = "filled", disabled = false, onClick, element}) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    if (element) {
        element(buttonRef)
    }

    return (
        <button className={"type-" + type} type={'button'} onClick={() => {
            onClick()
        }} ref={buttonRef}>
            {icon != null && (
                <img src={icon} alt={''}/>
            )}
            <div className={'btn-content'}>{children}</div>
        </button>
    );
};

export default Button;
