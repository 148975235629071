import React, {createRef, FunctionComponent, useEffect, useRef, useState} from 'react';
import {useDashboardFunc} from "../../../../../contexts/DashboardFuncContext";
import Spinner from "../../../../util/Spinner";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import "./TemperatureReadingsChart.scss";
import {useDashboard} from "../../../../../contexts/DashboardContext";
import {Messages} from "../../../../../data/messages";
import {CameraInfoOnline, TempReading} from "../../../../../data/camera";
import {getDateData, scaleBetween} from "../../../../../util/utils";
import Tooltip from "../../../../data/Tooltip";
import dayjs from "dayjs";
import tempLowIcon from "../../../../../assets/icons/material/temp_low.svg";
import tempHighIcon from "../../../../../assets/icons/material/temp_high.svg";

function fitToContainer(canvas: HTMLCanvasElement) {
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
}

const TemperatureReadingsChart: FunctionComponent = () => {
    const {send} = useDashboard();
    const {selectedCamera, tempReadings, cameraInfo} = useDashboardFunc();
    const windowSize = useWindowDimensions();

    const [points, setPoints] = useState<{ x: number, y: number, reading: TempReading }[]>([]);
    const [hoverPoint, setHoverPoint] = useState<number | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const pointsRef = useRef<React.RefObject<HTMLDivElement>[]>([])
    const [tempData, setTempData] = useState<{ min: TempReading, max: TempReading } | null>(null);
    const [updater, setUpdater] = useState(false);

    useEffect(() => {
        if (!selectedCamera) {
            return
        }

        if (!tempReadings) {
            send(Messages.REQUEST_TEMP_READINGS, {
                camera_id: selectedCamera.id
            })
        }

        let update = false;
        const interval = setInterval(() => {
            setUpdater(!update);
            update = !update;
        }, 1000 * 60);

        return () => {
            clearInterval(interval);
        }
    }, [selectedCamera])

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            return
        }
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return;
        }

        fitToContainer(canvas);

        const width = ctx.canvas.width;
        const height = ctx.canvas.height;

        console.log("READINGS");
        console.log(tempReadings);
        ctx.clearRect(0, 0, width, height);

        if (!tempReadings || tempReadings.length < 2) {
            return;
        }

        let readingsMinDate: Date | null = null, readingsMaxDate: Date | null = null;
        let filteredReadings: TempReading[] = [...tempReadings].reverse();
        for (let reading of tempReadings) {
            let date = reading.date;
            if (readingsMinDate == null || readingsMaxDate == null) {
                readingsMinDate = date;
                readingsMaxDate = date;
            } else {
                if (readingsMinDate.getTime() > date.getTime()) {
                    readingsMinDate = date;
                }
                if (readingsMaxDate.getTime() < date.getTime()) {
                    readingsMaxDate = date;
                }
            }
        }

        if (readingsMinDate == null || readingsMaxDate == null) {
            return;
        }

        const maxDiff = 1000 * 60 * 60 * 48;
        if (readingsMaxDate.getTime() - readingsMinDate.getTime() > maxDiff) {
            filteredReadings = tempReadings.filter((reading) => {
                return readingsMaxDate != null && readingsMaxDate.getTime() - reading.date.getTime() <= maxDiff;
            })
        }

        const leftOffset = 25;
        const rightOffset = 7;
        const totalDateSize = (width - leftOffset - rightOffset) / (readingsMaxDate.getTime() - readingsMinDate.getTime());

        let readingsMinTemp = null, readingsMaxTemp = null;
        let readingsMinTempDate = null, readingsMaxTempDate = null;
        for (let reading of filteredReadings) {
            let temp = reading.temperature;
            let date = reading.date;
            if (readingsMinTemp == null || readingsMaxTemp == null) {
                readingsMinTemp = temp;
                readingsMaxTemp = temp;
                readingsMinTempDate = date;
                readingsMaxTempDate = date;
            } else {
                if (readingsMinTemp > temp) {
                    readingsMinTemp = temp;
                    readingsMinTempDate = date;
                }
                if (readingsMaxTemp < temp) {
                    readingsMaxTemp = temp;
                    readingsMaxTempDate = date;
                }
            }
        }

        if (readingsMinTemp == null || readingsMaxTemp == null || readingsMinTempDate == null || readingsMaxTempDate == null) {
            return;
        }

        const tempScale = 2;
        // degree per pixel
        const scaleTempMin = 2 * Math.round((readingsMinTemp - tempScale) / 2);
        const scaleTempMax = 2 * Math.ceil((readingsMaxTemp + tempScale) / 2);
        const rows = (scaleTempMax - scaleTempMin) / tempScale;
        const pixelsPerRow = height / rows;

        //build background
        for (let temp = scaleTempMin, y = height - (pixelsPerRow / 2); temp < scaleTempMax && y >= 0; temp += tempScale, y -= pixelsPerRow) {
            ctx.beginPath();
            ctx.fillStyle = "#878787";
            ctx.font = "10px URW Geometric";
            ctx.fillText(temp + "°", 5, y + 2.5, 20);
            ctx.closePath();

            ctx.beginPath();
            ctx.strokeStyle = "#cacaca";
            ctx.moveTo(20, y);
            ctx.lineTo(width, y);
            ctx.stroke();
            ctx.closePath();
        }

        ctx.beginPath();
        ctx.strokeStyle = "#7872f1";
        ctx.lineWidth = 2;

        let arcs: { x: number, y: number, reading: TempReading, color: string }[] = [];
        let first = true;
        for (let reading of filteredReadings) {
            let x: number = width - rightOffset - ((readingsMaxDate.getTime() - reading.date.getTime()) * totalDateSize);
            //let y: number = height - (pixelsPerRow / 2) - (scaleBetween(reading.temperature, pixelsPerRow / 2, height - pixelsPerRow * 2, readingsMinTemp, readingsMaxTemp));
            let y: number = height - (pixelsPerRow / 2) - scaleBetween(reading.temperature, 0, height, scaleTempMin, scaleTempMax)

            if (first) {
                ctx.moveTo(x, y);
                first = false;
            } else {
                ctx.lineTo(x, y);
                ctx.moveTo(x, y);
            }
            arcs.push({
                x: x,
                y: y,
                reading: reading,
                color: (reading.temperature === readingsMaxTemp ? "#d33924" : (reading.temperature === readingsMinTemp ? "#34b7d5" : "#5a53ee"))
            });
        }

        ctx.stroke();
        ctx.closePath();

        for (let i = 0; i < arcs.length; i++) {
            let prevArc = i <= 0 ? null : arcs[i - 1];
            let nextArc = i >= arcs.length - 1 ? null : arcs[i + 1];
            let arc = arcs[i];

            let xBef = prevArc ? (arc.x + prevArc.x) / 2 : 0;
            let xAft = nextArc ? (arc.x + nextArc.x) / 2 : width;
            let {timeOfDay} = getDateData(arc.reading.date);
            if (timeOfDay === "night") {
                ctx.beginPath();
                ctx.fillStyle = '#6B7AEE22';
                ctx.rect(xBef, 0, xAft - xBef, height);
                ctx.fill();
                ctx.closePath();
            }
        }

        for (let arc of arcs) {
            ctx.fillStyle = arc.color;
            ctx.beginPath();
            ctx.arc(arc.x, arc.y, 4, 0, 2 * Math.PI);
            ctx.fill();
            ctx.closePath();
        }

        for (let i = 0; i < arcs.length; i++) {
            pointsRef.current[i] = createRef();
        }
        setPoints(arcs);
        setTempData({
            min: {date: readingsMinTempDate, temperature: readingsMinTemp},
            max: {date: readingsMaxTempDate, temperature: readingsMaxTemp}
        });
    }, [windowSize, tempReadings])


    if (!selectedCamera || !tempReadings || !(cameraInfo instanceof CameraInfoOnline)) {
        return (
            <div className={'temp-readings loading'}>
                <Spinner/>
            </div>
        );
    }

    let lastUpdate: Date | null = null;

    for (let tempReading of tempReadings) {
        if (!lastUpdate || lastUpdate.getTime() < tempReading.date.getTime()) {
            lastUpdate = tempReading.date;
        }
    }

    return (
        <div className={'temp-readings'}>
            <div className={'temp-readings-chart'}>
                <canvas ref={canvasRef}></canvas>
                <div className={'points'}>
                    {points.map((point, index) => (
                        <>
                            <div className={'point'} ref={pointsRef.current[index]}
                                 style={{top: (point.y - 5) + "px", left: (point.x - 5) + "px"}}
                                 key={point.x + "_" + point.y + "_" + index} onMouseEnter={() => {
                                setHoverPoint(index)
                            }} onMouseLeave={() => {
                                setHoverPoint(null)
                            }}>

                            </div>
                            <Tooltip element={pointsRef.current[index]} position={"left"} show={hoverPoint === index}
                                     classes={"point-tooltip"} arrow={false}>
                                <span className={'temperature'}>
                                    {point.reading.temperature.toFixed(2)}°C
                                </span>
                                <span className={'date'}>
                                    {dayjs(point.reading.date).format("YYYY-MM-DD HH:mm:ss")}
                                </span>
                            </Tooltip>
                        </>
                    ))}
                </div>
                {pointsRef.current.length < 2 ? (
                    <div className={'no-readings'}>
                        Brak odczytów
                    </div>
                ) : null}
            </div>
            <div className={'temp-last-update'}>
                {lastUpdate ? "Ostatnia aktualizacja " + dayjs(lastUpdate).fromNow() : "Brak aktualizacji"}
            </div>
            <div className={'temp-readings-values'}>
                <section>
                    <div className={'inline'}>
                        <img src={tempLowIcon} className={'low-temp'}/>
                        <p>Minimalna temperatura</p>
                        <span className={'temperature'}>
                            {tempData ? (tempData.min.temperature > cameraInfo.temperature ? cameraInfo.temperature : tempData.min.temperature).toFixed(1) + "°C" : "?"}
                        </span>
                    </div>
                    <span className={'date'}>
                        {tempData ? (tempData.min.temperature > cameraInfo.temperature ? "teraz" : dayjs(tempData.min.date).fromNow()) : ""}
                    </span>
                </section>
                <section>
                    <div className={'inline'}>
                        <img src={tempHighIcon} className={'high-temp'}/>
                        <p>Maksymalna temperatura</p>
                        <span className={'temperature'}>
                            {tempData ? (tempData.max.temperature < cameraInfo.temperature ? cameraInfo.temperature : tempData.max.temperature).toFixed(1) + "°C" : "?"}
                        </span>
                    </div>
                    <span className={'date'}>
                        {tempData ? (tempData.max.temperature < cameraInfo.temperature ? "teraz" : dayjs(tempData.max.date).fromNow()) : ""}
                    </span>
                </section>
            </div>
        </div>
    );
};

export default TemperatureReadingsChart;
