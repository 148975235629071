import React, {createContext, FunctionComponent, ReactNode, useContext, useEffect, useRef, useState} from 'react';

export type ValueHandlerProps = {
    [key: string]: { value: string; error: string | null; }
}

interface FormProviderProps {
    children: ReactNode;
    /**
     * @param submit callback to submit, returns if form is valid
     */
    submitHandler: (submit: () => boolean) => void;
    valueHandler: (getValue: () => ValueHandlerProps) => void;
    invalidSetHandler: (setValue: (name: string, invalidValue: string, error: string) => void) => void;
}

type Props = FormProviderProps;

type ValueGetterProps = {
    value: string;
    error: string | null;
}

interface FormContextProps {
    onSubmit: (name: string, submit: () => boolean) => void;
    onValueGetter: (name: string, getter: () => ValueGetterProps) => void;
    onInvalidValueSet: (name: string, setter: (value: string, error: string) => void) => void;
}

const FormContext = createContext<FormContextProps | null>(null);

export function useForm() {
    return useContext(FormContext) || {} as FormContextProps;
}

const FormProvider: FunctionComponent<Props> = ({children, submitHandler, valueHandler, invalidSetHandler}) => {
    const [submitEvents, setSubmitEvents] = useState<{ [key: string]: (() => boolean) }>({});
    const [valueGetters, setValueGetters] = useState<{ [key: string]: (() => ValueGetterProps) }>({});
    const [invalidValueSetters, setInvalidValueSetters] = useState<{ [key: string]: ((value: string, error: string) => void) }>({});

    const formRef = useRef<HTMLFormElement>(null);

    const focusInvalid = () => {
        if (formRef.current) {
            const invalid: HTMLDivElement | null = formRef.current.querySelector('.input-field.invalid');
            if (invalid) {
                invalid.click();
            }
        }
    }

    useEffect(() => {
        submitHandler(() => {
            let valid = true;
            for (let key in submitEvents) {
                const event = submitEvents[key];

                if (!event()) {
                    valid = false;
                }
            }

            setTimeout(() => {
                focusInvalid();
            }, 10)
            return valid;
        })

        valueHandler(() => {
            let values: ValueHandlerProps = {};
            for (let key in valueGetters) {
                const event = valueGetters[key];
                const result = event();

                values[key] = {
                    value: result.value,
                    error: result.error
                }
            }

            return values;
        });

        invalidSetHandler((name, value, error) => {
            const event = invalidValueSetters[name];

            if (event != null) {
                event(value, error);
            }

            setTimeout(() => {
                focusInvalid();
            }, 10)
        })
    }, [])

    const onSubmit = (name: string, submit: () => boolean): void => {
        let submitEventsCapture = submitEvents;
        submitEventsCapture[name] = submit;
        setSubmitEvents(submitEventsCapture);
    }
    const onValueGetter = (name: string, getter: () => ValueGetterProps): void => {
        let gettersCapture = valueGetters;
        gettersCapture[name] = getter;
        setValueGetters(gettersCapture);
    }
    const invalidValuesSetter = (name: string, setter: (value: string, error: string) => void): void => {
        let settersCapture = invalidValueSetters;
        settersCapture[name] = setter;
        setInvalidValueSetters(settersCapture);
    }

    const value: FormContextProps = {
        onSubmit,
        onValueGetter,
        onInvalidValueSet: invalidValuesSetter
    }

    return (<FormContext.Provider value={value}>
        <form ref={formRef}>{children}</form>
    </FormContext.Provider>);
};

export default FormProvider;
