import React, {FunctionComponent} from 'react';
import "./MemoryBar.scss";
import {humanFileSize} from "../../../../../util/utils";

interface MemoryProps {
    totalBytes: number;
    usedBytes: number;
}

type Props = MemoryProps;


const MemoryBar: FunctionComponent<Props> = ({totalBytes, usedBytes}) => {
    const progress = usedBytes / totalBytes * 100;

    return (
        <div className={'memory-bar-wrapper'}>
            <div className={'progress-bar'}>
                <div className={'fill'}
                     style={{maxWidth: progress + "%"}}/>
            </div>
            <div className={'progress-values'}>
                <p className={'bytes'}>{humanFileSize(usedBytes, false, true)}&nbsp;
                    <span>/</span>&nbsp;{humanFileSize(totalBytes, false, true)}
                </p>
                <p className={'progress'}>{progress.toFixed(0)}%</p>
            </div>
        </div>
    );
};

export default MemoryBar;
