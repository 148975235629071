import React, {FunctionComponent, useEffect, useState} from 'react';
import "./AccountCameraPreview.scss";
import {getAverageColor} from "../../util/utils";
import {Camera} from "../../data/camera";

interface DashboardCameraPreviewProps {
    camera: Camera;
    onSelect: () => void;
}

type Props = DashboardCameraPreviewProps;

const AccountCameraPreview: FunctionComponent<Props> = ({camera, onSelect}) => {
    const [color, setColor] = useState<string | null>(null);
    const [cameraPreview, setCameraPreview] = useState<string>(camera.getLocalScreenshot());

    useEffect(() => {
        getAverageColor(cameraPreview, (c) => {
            if (c) {
                setColor('#' + c);
            }
        });

    }, []);

    return (
        <div
            className={'account-camera-preview'}
            onClick={onSelect}>
            <div className={'image-preview'}
                 style={color ? {outlineColor: color, boxShadow: '0 4px 8px ' + color + "78"} : {}}>
                <img src={cameraPreview} alt={'Preview'}/>
            </div>
            <h3 className={'camera-title'}>{camera.name}</h3>
        </div>
    );
};

export default AccountCameraPreview;
