import React, {FunctionComponent, ReactNode, useState} from 'react';

interface InfoBlockProps {
    children: ReactNode;
    title: string;
    icon: string;
    onClick?: () => void;
    classes?: string;
}

type Props = InfoBlockProps;

const InfoBlock: FunctionComponent<Props> = ({children, title, icon, onClick, classes = ""}) => {
    const [loading, setLoading] = useState(false);

    const click = () => {
        if (onClick) {
            setLoading(true);
            onClick();
        }
    }

    return (
        <div
            className={'camera-info-block ' + classes + " " + (loading ? "loading" : "") + " " + (onClick ? "clickable" : "")}
            onClick={() => click()}>
            <img src={icon} className={'info-icon'}/>
            <h5>{title}</h5>
            <div className={'line'}/>
            <div className={'camera-info-block-content'}>
                {children}
            </div>
        </div>
    );
};

export default InfoBlock;
