import React, {FunctionComponent, ReactNode} from 'react';

interface CameraSettingRowProps {
    title: ReactNode;
    description?: ReactNode;
    titleClasses?: string;
    descriptionClasses?: string;
    dataClasses?: string;
    children: ReactNode;
}

type Props = CameraSettingRowProps;

const CameraSettingRow: FunctionComponent<Props> = ({
                                                        title,
                                                        description = "",
                                                        titleClasses = "",
                                                        descriptionClasses = "",
                                                        dataClasses = "",
                                                        children
                                                    }) => {

    return (
        <>
            <div className={'setting ' + (description == null ? "without-desc" : "with-desc")}>
                {description == null ? (
                    <>
                        <p className={'setting-title ' + titleClasses}>{title}</p>
                        <div className={'setting-data ' + dataClasses}>
                            {children}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'title-data-row'}>
                            <p className={'setting-title ' + titleClasses}>{title}</p>
                            <div className={'setting-data ' + dataClasses}>
                                {children}
                            </div>
                        </div>
                        <div className={'description ' + descriptionClasses}>
                            {description}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CameraSettingRow;
