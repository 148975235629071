import React, {FunctionComponent, useRef, useState} from 'react';
import {CameraAccessor} from "../../../../data/camera";
import UserAvatar from "../../../data/UserAvatar";
import adminIcon from "../../../../assets/icons/material/admin.svg";
import removeIcon from "../../../../assets/icons/material/close.svg";
import Tooltip from "../../../data/Tooltip";
import Button from "../../../forms/Button";
import {DashboardContextProps, useDashboard} from "../../../../contexts/DashboardContext";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import {Messages} from "../../../../data/messages";
import Spinner from "../../../util/Spinner";

interface AccessorProps {
    accessor: CameraAccessor;
    admin: boolean;
}

type Props = AccessorProps;

const changeAdmin = async (context: DashboardContextProps, cameraId: number, userId: number, newValue: boolean): Promise<boolean> => {
    const promise = context.once(Messages.RESPOND_ACCESSOR_RESULT_CHANGE_ADMIN, 5000);
    context.send(Messages.REQUEST_ACCESSOR_CHANGE_ADMIN, {
        camera_id: cameraId,
        user_id: userId,
        admin: newValue
    });

    const data = await promise;
    if (!data) {
        return false;
    }

    const json = JSON.parse(data);
    if (!json) {
        return false
    }

    return json['success'];
}
const remove = async (context: DashboardContextProps, cameraId: number, userId: number): Promise<boolean> => {
    const promise = context.once(Messages.RESPOND_ACCESSOR_RESULT_REMOVE, 5000);
    context.send(Messages.REQUEST_ACCESSOR_REMOVE, {
        camera_id: cameraId,
        user_id: userId
    });

    const data = await promise;
    if (!data) {
        return false;
    }

    const json = JSON.parse(data);
    if (!json) {
        return false
    }

    return json['success'];
}

const DSSettingsAccessorEntry: FunctionComponent<Props> = ({accessor, admin}) => {
    const dashboardContext = useDashboard();
    const {selectedCamera, refreshCameraInfo, refreshCameraSettings} = useDashboardFunc();

    const [changeAdminHover, setChangeAdminHover] = useState(false);
    const changeAdminIconRef = useRef<HTMLImageElement>(null);
    const [changeAdminLoading, setChangeAdminLoading] = useState(false);

    const [removeAccessorHover, setRemoveAccessorHover] = useState(false);
    const removeAccessorIconRef = useRef<HTMLImageElement>(null)
    const [removeAccessorLoading, setRemoveAccessorLoading] = useState(false);

    if (!selectedCamera) {
        return null;
    }

    return (
        <div className={'settings-accessor'}>
            <div className={'accessor-avatar-wrapper'}>
                <UserAvatar user={accessor.user}/>
            </div>
            <div className={'accessor-info'}>
                <h4 className={'accessor-nick'}>{accessor.user.nick}</h4>
            </div>
            {admin ? (
                <div className={'accessor-manage'}>
                    <div className={'icon-holder'} onMouseEnter={() => setChangeAdminHover(true)}
                         onMouseLeave={() => setChangeAdminHover(false)}>
                        <img src={adminIcon} ref={changeAdminIconRef} alt={'add user'}
                             className={'title-icon admin-icon ' + (accessor.admin ? "admin" : "")}/>
                        <Tooltip element={changeAdminIconRef} position={"right"}
                                 show={changeAdminHover || changeAdminLoading}
                                 classes={'accessor-tooltip admin-change-tooltip'}>
                            <div className={'info'}>
                                Ten użytkownik {accessor.admin ? (<span className={'admin'}>ma uprawnienia</span>) :
                                <span className={'no-admin'}>nie ma uprawnień</span>} administrowania tej kamery.
                            </div>
                            <Button type={"filled-dark"} onClick={() => {
                                setChangeAdminLoading(true);
                                (async () => {
                                    const result = await changeAdmin(dashboardContext, selectedCamera.id, accessor.user.id, !accessor.admin);
                                    if (result) {
                                        refreshCameraInfo();
                                        refreshCameraSettings();
                                        setChangeAdminHover(false);
                                    }

                                    //todo error

                                    setChangeAdminLoading(false);
                                })();
                            }}>
                                {changeAdminLoading ? <Spinner
                                    type={'background'}/> : (accessor.admin ? "Odbierz uprawnienia" : "Nadaj uprawnienia")}
                            </Button>
                        </Tooltip>
                    </div>
                    <div className={'icon-holder'} onMouseEnter={() => setRemoveAccessorHover(true)}
                         onMouseLeave={() => setRemoveAccessorHover(false)}>
                        <img src={removeIcon} ref={removeAccessorIconRef} alt={'add user'} className={'title-icon'}/>
                        <Tooltip element={removeAccessorIconRef} position={"right"}
                                 show={removeAccessorHover || removeAccessorLoading}
                                 classes={'accessor-tooltip remove-user-tooltip'}>
                            <Button type={"filled-dark"} onClick={() => {
                                setRemoveAccessorLoading(true);
                                (async () => {
                                    const result = await remove(dashboardContext, selectedCamera.id, accessor.user.id);
                                    if (result) {
                                        refreshCameraInfo();
                                        refreshCameraSettings();
                                        setRemoveAccessorHover(false);
                                    }

                                    //todo error

                                    setRemoveAccessorLoading(false);
                                })();
                            }}>
                                {removeAccessorLoading ? <Spinner type={'background'}/> : (
                                    <span className={'text'}>Usuń użytkownika</span>)}
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default DSSettingsAccessorEntry;
