import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import "./DSSettingsCamera.scss";
import saveIcon from "../../../../assets/icons/material/save.svg";
import sdIcon from "../../../../assets/icons/material/save.svg";
import CameraSettingRow from "./camera_settings/CameraSettingRow";
import FormatChooser from "./camera_settings/FormatChooser";
import QualityChooser from "./camera_settings/QualityChooser";
import WiFiCredentials from "./camera_settings/WiFiCredentials";
import Button from "../../../forms/Button";
import {useDashboardFunc} from "../../../../contexts/DashboardFuncContext";
import Spinner from "../../../util/Spinner";
import {useDashboard} from "../../../../contexts/DashboardContext";
import {Messages} from "../../../../data/messages";
import Modal from "../../../data/Modal";
import DSSettingsSaveModal from "./save/DSSettingsSaveModal";
import powerIcon from "../../../../assets/icons/material/power.svg";
import removeIcon from "../../../../assets/icons/material/remove.svg";
import resetSettingsIcon from "../../../../assets/icons/material/reset_settings.svg";
import debugIcon from "../../../../assets/icons/material/debug.svg";
import {useAlert} from "../../../../contexts/AlertContext";
import AdvancedButton from "./advanced/AdvancedButton";
import DSSettingsAdvancedModal from "./advanced/DSSettingsAdvancedModal";
import MemoryBar from "./memory/MemoryBar";
import Checkbox from "../../../forms/Checkbox";
import TimezoneChooser from "./timezone/TimezoneChooser";
import DSSettingsDebugModal from "./debug/DSSettingsDebugModal";
import ValueChooser from "./camera_settings/ValueChooser";

interface Settings {
    format: { value: string, newValue: string | null };
    quality: { value: number, newValue: number | null };
    brightness: { value: number, newValue: number | null };
    contrast: { value: number, newValue: number | null };
    saturation: { value: number, newValue: number | null };
    sharpness: { value: number, newValue: number | null };
    denoise: { value: number, newValue: number | null };
    wifi_ssid: string | null;
    wifi_password: string | null;
    picture_blink: { value: boolean, newValue: boolean | null };
    time_zone: { value: number, newValue: number | null };
}

const DSSettingsCamera: FunctionComponent = () => {
    const {send, once} = useDashboard();
    const {selectedCamera, cameraSettings, refreshCameraSettings, refreshingCameraSettings} = useDashboardFunc();
    const {addAlert} = useAlert();

    const [needSave, setNeedSave] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [debugModal, setDebugModal] = useState(false);
    const [advancedEnabled, setAdvancedEnabled] = useState(false);
    const saveIconRef = useRef<HTMLImageElement>(null);
    const [changedVals, setChangedVals] = useState<{ [key: string]: any } | null>(null);

    const [formatSdModal, setFormatSdModal] = useState(false);
    const [resetModal, setResetModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);


    const settingsRef = useRef<Settings | null>(null)

    useEffect(() => {
        if (!selectedCamera || !cameraSettings || !cameraSettings.admin) {
            return
        }

        settingsRef.current = {
            format: {value: cameraSettings.format, newValue: null},
            quality: {value: cameraSettings.quality, newValue: null},
            brightness: {value: cameraSettings.brightness, newValue: null},
            contrast: {value: cameraSettings.contrast, newValue: null},
            saturation: {value: cameraSettings.saturation, newValue: null},
            sharpness: {value: cameraSettings.sharpness, newValue: null},
            denoise: {value: cameraSettings.denoise, newValue: null},
            wifi_ssid: null,
            wifi_password: null,
            picture_blink: {value: cameraSettings.pictureBlink, newValue: null},
            time_zone: {value: cameraSettings.timeZone, newValue: null}
        }
    }, [cameraSettings])

    const getChangedValues = (): { [key: string]: any } | null => {
        if (!settingsRef.current) {
            return null;
        }

        const changed: { [key: string]: any } = {};
        let change = false;

        if (settingsRef.current.format.value !== settingsRef.current.format.newValue && settingsRef.current.format.newValue != null) {
            changed["format"] = settingsRef.current.format.newValue;
            change = true;
        }
        if (settingsRef.current.quality.value !== settingsRef.current.quality.newValue && settingsRef.current.quality.newValue != null) {
            changed["quality"] = settingsRef.current.quality.newValue;
            change = true;
        }
        if (settingsRef.current.brightness.value !== settingsRef.current.brightness.newValue && settingsRef.current.brightness.newValue != null) {
            changed["brightness"] = settingsRef.current.brightness.newValue;
            change = true;
        }
        if (settingsRef.current.contrast.value !== settingsRef.current.contrast.newValue && settingsRef.current.contrast.newValue != null) {
            changed["contrast"] = settingsRef.current.contrast.newValue;
            change = true;
        }
        if (settingsRef.current.saturation.value !== settingsRef.current.saturation.newValue && settingsRef.current.saturation.newValue != null) {
            changed["saturation"] = settingsRef.current.saturation.newValue;
            change = true;
        }
        if (settingsRef.current.sharpness.value !== settingsRef.current.sharpness.newValue && settingsRef.current.sharpness.newValue != null) {
            changed["sharpness"] = settingsRef.current.sharpness.newValue;
            change = true;
        }
        if (settingsRef.current.denoise.value !== settingsRef.current.denoise.newValue && settingsRef.current.denoise.newValue != null) {
            changed["denoise"] = settingsRef.current.denoise.newValue;
            change = true;
        }
        if (settingsRef.current.wifi_ssid != null) {
            changed["wifi_ssid"] = settingsRef.current.wifi_ssid;
            change = true;
        }
        if (settingsRef.current.wifi_password != null) {
            changed["wifi_password"] = settingsRef.current.wifi_password;
            change = true;
        }
        if (settingsRef.current.picture_blink.value !== settingsRef.current.picture_blink.newValue && settingsRef.current.picture_blink.newValue != null) {
            changed["picture_blink"] = settingsRef.current.picture_blink.newValue;
            change = true;
        }
        if (settingsRef.current.time_zone.value !== settingsRef.current.time_zone.newValue && settingsRef.current.time_zone.newValue != null) {
            changed["time_zone"] = settingsRef.current.time_zone.newValue;
            change = true;
        }

        if (!change) {
            return null;
        }

        return changed;
    }

    const save = (): boolean => {
        console.log("Saving")
        if (!settingsRef.current) {
            return false
        }
        if (!selectedCamera) {
            return false
        }

        const changed = getChangedValues();
        if (!changed) {
            return false
        }

        (async () => {
            const promise = once(Messages.RESPOND_SETTINGS_CHANGE, 5000, (data) => {
                const json = JSON.parse(data);
                if (!json) {
                    return false;
                }

                if (!json.hasOwnProperty('camera')) {
                    return false;
                }

                return json['camera'] === selectedCamera.id;
            });

            setSaving(true);
            send(Messages.REQUEST_SETTINGS_CHANGE, {
                id: selectedCamera.id,
                data: changed
            });
            const response = await promise;
            setSaving(false);
            setNeedSave(false);

            if (!response) {
                addAlert(saveIcon, "Nie można zapisać ustawień kamery.", null, "error");
                revert();
                return
            }

            const json = JSON.parse(response);
            if (!json) {
                addAlert(saveIcon, "Błąd w odczycie odpowiedzi od kamery.", null, "error");
                revert();
                return
            }

            if (json['success']) {
                // ok
                addAlert(saveIcon, "Zapisano nowe ustawienia kamery.", null);
                refreshCameraSettings();
            } else {
                // show error and revert
                addAlert(saveIcon, "Nie można zapisać ustawień kamery.", null, "error");
                revert();
            }
        })();

        return true;
    }

    const changed = () => {
        if (!settingsRef.current) {
            return
        }

        const changed = getChangedValues();
        setChangedVals(changed);
        setNeedSave(changed != null);
    }

    const revert = () => {
        if (!settingsRef.current) {
            return
        }

        settingsRef.current.format.newValue = null;
        settingsRef.current.quality.newValue = null;
        settingsRef.current.brightness.newValue = null;
        settingsRef.current.contrast.newValue = null;
        settingsRef.current.saturation.newValue = null;
        settingsRef.current.sharpness.newValue = null;
        settingsRef.current.denoise.newValue = null;
        settingsRef.current.wifi_ssid = null;
        settingsRef.current.wifi_password = null;
        settingsRef.current.picture_blink.newValue = null;
        settingsRef.current.time_zone.newValue = null;

        changed();
    }

    if (!selectedCamera || !cameraSettings) {
        return <div className={'camera-settings-camera-loading'}>
            Access denied.
        </div>
    }
    if (!cameraSettings.admin) {
        return <div className={'camera-settings-camera-error'}>
            Access denied.
        </div>
    }

    if (!settingsRef.current) {
        return <Spinner/>;
    }

    return (
        <div className={'camera-settings-camera'}>
            <div className={'camera-settings-title-wrapper'}>
                <h3 className={'camera-settings-title'}>Dane kamery</h3>
                {refreshingCameraSettings ? (<div className={'saving-holder'}>
                    <span className={'info'}>Ładowanie danych...</span>
                    <Spinner type={"primary"}/>
                </div>) : null}
            </div>
            <div className={'camera-settings-settings'}>
                <section>
                    <div className={'section-title'}>
                        <div className={'line'}/>
                        <h4>Pamięć</h4>
                    </div>
                    <CameraSettingRow title={"Karta SD"}
                                      description={null}>
                        <MemoryBar totalBytes={cameraSettings.sdTotalSize} usedBytes={cameraSettings.sdUsedSize}/>
                    </CameraSettingRow>
                </section>
            </div>
            <div className={'camera-settings-title-wrapper'}>
                <h3 className={'camera-settings-title'}>Ustawienia kamery</h3>
                {refreshingCameraSettings ? (<div className={'saving-holder'}>
                    <span className={'info'}>Ładowanie ustawień...</span>
                    <Spinner type={"primary"}/>
                </div>) : saving ? (
                    <div className={'saving-holder'}>
                        <span className={'info'}>Zapisywanie zmian...</span>
                        <Spinner type={"primary"}/>
                    </div>) : (needSave ? (
                    <div className={'save-holder'}>
                        <span className={'info'}>
                            Zmiany wymagają zapisania
                        </span>
                        <Button onClick={() => {
                            setSaveModal(true);
                        }}>
                            <img alt={'save'} src={saveIcon}/>
                            <span className={'text'}>
                                Zapisz...
                            </span>
                        </Button>
                    </div>
                ) : null)}
            </div>
            <div className={'camera-settings-settings'}>
                <section>
                    <div className={'section-title'}>
                        <div className={'line'}/>
                        <h4>Obraz</h4>
                    </div>
                    <CameraSettingRow title={"Format nagrywania"}
                                      description={"Im większy format, tym lepsza jakość, lecz mniejsza płynność obrazu."}>
                        <FormatChooser format={settingsRef.current.format.newValue || settingsRef.current.format.value}
                                       onSet={(format) => {
                                           if (settingsRef.current?.format) {
                                               settingsRef.current.format.newValue = format.name;
                                               changed();
                                               return true;
                                           }
                                           return false;
                                       }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Jakość"}
                                      description={"Im mniejsza jakość, tym większa płynność przesyłanego obrazu."}>
                        <QualityChooser
                            quality={settingsRef.current.quality.newValue || settingsRef.current.quality.value}
                            onSet={(quality) => {
                                if (settingsRef.current?.quality) {
                                    settingsRef.current.quality.newValue = quality.value;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Jasność"}>
                        <ValueChooser
                            value={settingsRef.current.brightness.newValue || settingsRef.current.brightness.value}
                            onSet={(newValue) => {
                                if (settingsRef.current?.brightness) {
                                    settingsRef.current.brightness.newValue = newValue;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Kontrast"}>
                        <ValueChooser
                            value={settingsRef.current.contrast.newValue || settingsRef.current.contrast.value}
                            onSet={(newValue) => {
                                if (settingsRef.current?.contrast) {
                                    settingsRef.current.contrast.newValue = newValue;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Nasycenie"}>
                        <ValueChooser
                            value={settingsRef.current.saturation.newValue || settingsRef.current.saturation.value}
                            onSet={(newValue) => {
                                if (settingsRef.current?.saturation) {
                                    settingsRef.current.saturation.newValue = newValue;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Ostrość"}>
                        <ValueChooser
                            value={settingsRef.current.sharpness.newValue || settingsRef.current.sharpness.value}
                            onSet={(newValue) => {
                                if (settingsRef.current?.sharpness) {
                                    settingsRef.current.sharpness.newValue = newValue;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Odszumianie"}>
                        <ValueChooser
                            value={settingsRef.current.denoise.newValue || settingsRef.current.denoise.value}
                            onSet={(newValue) => {
                                if (settingsRef.current?.denoise) {
                                    settingsRef.current.denoise.newValue = newValue;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                </section>
                <section>
                    <div className={'section-title'}>
                        <div className={'line'}/>
                        <h4>Połączenie</h4>
                    </div>
                    <CameraSettingRow title={"Nazwa sieci"}>
                        <WiFiCredentials type={"sid"} value={settingsRef.current.wifi_ssid || ""} onSet={(value) => {
                            if (settingsRef.current) {
                                settingsRef.current.wifi_ssid = value;
                                changed();
                                return true;
                            }
                            return false;
                        }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Hasło"}
                                      description={"Jeżeli kamera nie będzie w stanie połączyć się przez 1 minutę, stare dane zostaną przywrócone."}>
                        <WiFiCredentials type={"pass"} value={settingsRef.current.wifi_password || ""}
                                         onSet={(value) => {
                                             if (settingsRef.current) {
                                                 settingsRef.current.wifi_password = value;
                                                 changed();
                                                 return true;
                                             }
                                             return false;
                                         }}/>
                    </CameraSettingRow>
                </section>
                <section>
                    <div className={'section-title'}>
                        <div className={'line'}/>
                        <h4>Inne</h4>
                    </div>
                    <CameraSettingRow title={"Znak zdjęcia"}
                                      description={"Podczas robienia zdjęcia kamera mrugnie diodą."}>
                        <Checkbox
                            value={settingsRef.current.picture_blink.newValue || settingsRef.current.picture_blink.value}
                            onChange={(value) => {
                                if (settingsRef.current?.picture_blink) {
                                    settingsRef.current.picture_blink.newValue = value;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                    <CameraSettingRow title={"Strefa czasowa"}
                                      description={"Strefa czasowa UTC, której czas ma pokazywać kamera."}>
                        <TimezoneChooser
                            timezone={settingsRef.current.time_zone.newValue || settingsRef.current.time_zone.value}
                            onSet={(value) => {
                                if (settingsRef.current?.time_zone) {
                                    settingsRef.current.time_zone.newValue = value;
                                    changed();
                                    return true;
                                }
                                return false;
                            }}/>
                    </CameraSettingRow>
                </section>
                <section className={'advanced'}>
                    <div className={'section-title advanced'}>
                        <div className={'line'}/>
                        <h4>Zaawansowane</h4>
                    </div>
                    {advancedEnabled ? (<>
                        <CameraSettingRow title={"Uruchom ponownie"}
                                          description={<>Wstrzymuje wszystkie procesy i uruchamia ponownie kamerę.</>}>
                            <AdvancedButton onClick={() => {
                                if (!selectedCamera) {
                                    addAlert(powerIcon, "Kamera jest niedostępna, nie można uruchomić jej ponownie.", null, 'error');
                                    return false;
                                }

                                const promise = once(Messages.STATUS, 5000, (data) => {
                                    const json = JSON.parse(data);
                                    if (!json) {
                                        return false;
                                    }

                                    if (json['name'] !== "restart") {
                                        return false;
                                    }

                                    return json['camera'] === selectedCamera.id;
                                })
                                send(Messages.REQUEST_RESTART, {
                                    camera_id: selectedCamera.id
                                });
                                promise.then((data) => {
                                    if (!data) {
                                        addAlert(powerIcon, "Uruchamianie kamery ponownie nie powiodło się.", null, 'error');
                                        return;
                                    }

                                    const json = JSON.parse(data);
                                    if (!json) {
                                        addAlert(powerIcon, "Uruchamianie kamery ponownie nie powiodło się.", null, 'error');
                                        return;
                                    }

                                    if (json['status']) {
                                        addAlert(powerIcon, "Kamera uruchamia się ponownie.", null, 'success');
                                    } else {
                                        addAlert(powerIcon, "Uruchamianie kamery ponownie nie powiodło się. Kod błędu: " + json['side'] + json['error'], null, 'error');
                                    }
                                })

                                return true;
                            }} icon={powerIcon} load={true}/>
                        </CameraSettingRow>
                        <CameraSettingRow title={"Debuguj"}
                                          description={"Otwiera konsolę debugowania"}>
                            <AdvancedButton onClick={() => {
                                setDebugModal(true);
                                return true;
                            }} icon={debugIcon}/>
                        </CameraSettingRow>
                        <CameraSettingRow title={"Formatuj kartę SD"}
                                          description={<>Usuwa wszystkie dane na karcie SD.</>}>
                            <AdvancedButton onClick={() => {
                                setFormatSdModal(true);
                                return true;
                            }} icon={sdIcon}/>

                            <Modal show={formatSdModal} classes={"modal-advanced"}>
                                <DSSettingsAdvancedModal onClose={() => {
                                    setFormatSdModal(false);
                                }} onAction={(password) => {
                                    console.log("Action: " + password)
                                }} title={"Formatowanie karty SD"}
                                                         description={"Usuń wszystkie dane na karcie SD. Wszystkie zdjęcia zostaną utracone."}
                                                         icon={sdIcon} camera={selectedCamera}/>
                            </Modal>
                        </CameraSettingRow>
                        <CameraSettingRow title={"Resetuj ustawienia"}
                                          description={<>Zresetuj kamerę do ustawień fabrycznych. <span
                                              className='warn'>Uwaga: </span> karta SD również zostanie
                                              sformatowana.</>}>
                            <AdvancedButton onClick={() => true} icon={resetSettingsIcon}/>
                        </CameraSettingRow>
                        <CameraSettingRow title={"Usuń kamerę"}
                                          description={<>Usuwa kamerę z systemu i wszystkie jej dane, a także resetuje
                                              ją do ustawień fabrycznych.<br/>Nie będzie już więcej dostępna, chyba, że
                                              spowrotem ją dodasz.</>}>
                            <AdvancedButton onClick={() => true} icon={removeIcon}/>
                        </CameraSettingRow>
                    </>) : (<div className={'advanced-button-holder'}>
                        <Button type={'filled-error'} onClick={() => {
                            setAdvancedEnabled(true)
                        }}>
                            Pokaż zaawansowane
                        </Button>
                    </div>)}
                </section>
            </div>

            {changedVals == null ? null : (
                <Modal show={saveModal} classes={"modal-save"}>
                    <DSSettingsSaveModal onClose={() => {
                        setSaveModal(false);
                    }} onAction={(action) => {
                        if (action === "revert") {
                            revert();
                        } else if (action === "save") {
                            save();
                        }
                    }} changedSettings={changedVals}/>
                </Modal>
            )}

            <Modal show={debugModal} classes={"modal-debug"}>
                <DSSettingsDebugModal show={debugModal} onClose={() => {
                    setDebugModal(false);
                }}/>
            </Modal>
        </div>
    );
};

export default DSSettingsCamera;
