import React, {FunctionComponent} from 'react';

import logo from "./../../assets/icons/mcam_light.svg";
import {NavLink, useLocation} from "react-router-dom";
import './NavbarHome.scss';
import {useAuth} from "../../contexts/AuthContext";
import SelfAvatar from "../data/SelfAvatar";

const NavbarHome: FunctionComponent = () => {
    const {pathname} = useLocation();
    const {getFromCookies} = useAuth();

    return (
        <header className={'home-navbar'}>
            <div className={'header-wrapper'}>
                <img src={logo} alt={"MCam"}/>
                <nav>
                    <NavLink to={'/'}>Strona Główna</NavLink>
                    {getFromCookies() ? (
                        <>
                            <NavLink to={'/dashboard'}>Panel</NavLink>
                        </>
                    ) : (
                        <NavLink to={'/login'}
                                 className={['/register'].includes(pathname) ? "active" : ""}>Logowanie</NavLink>
                    )}
                </nav>
            </div>
            {getFromCookies() && (
                <div className={'avatar-holder'}>
                    <SelfAvatar/>
                </div>
            )}
        </header>
    );
};

export default NavbarHome;
